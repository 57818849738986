import { ApiService } from "./ApiService";
import { User } from "../entities/Subscriber";

export class PrivacyService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getUserInfo(email: string): Promise<User> {
        return await this.apiService.post("privacy/userinfo", JSON.stringify(email), {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    public async getUserInfoInOrganization(email: string): Promise<User> {
        return await this.apiService.post("privacy/userinorganization", JSON.stringify(email), {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    public async deleteUser(id: string): Promise<string> {
        return await this.apiService.post("privacy/deleteuser", JSON.stringify(id), {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
}

import { ApiService } from ".";
import { Suppression } from "../entities/Suppression";

export class SuppressionsService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getSuppressions(email: string): Promise<Suppression[]> {
        return await this.apiService.getNoOrg(`suppressions/email?email=${encodeURIComponent(email)}`);
    }

    public async deleteSuppressions(email: string): Promise<void> {
        return await this.apiService.makeRequest(
            `${window.location.origin}/api/suppressions/email?email=${encodeURIComponent(email)}`,
            "DELETE",
            null,
            {}
        );
    }
}

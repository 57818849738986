import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { getUsersName } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { bottle } from "../provider/Bottle";
import { HcmsService } from "../services/HcmsService";
import { OrganizationService } from "../services/OrganizationService";

export const searchUsers = async (search: string) => {
    const service: OrganizationService = bottle.container.OrganizationService;
    return await service.searchOrgUsers(search).then((data) =>
        data.map((user) => ({
            label: getUsersName(user),
            value: user.id
        }))
    );
};

export const searchUsersAndClients = async (search: string): Promise<OptionShape[]> => {
    const hcmsService: HcmsService = bottle.container.HcmsService;
    const organizationService: OrganizationService = bottle.container.OrganizationService;
    const orgDto = await organizationService.getOrganization();
    search = search.toLowerCase();

    let users: OptionShape[] = [];
    const clients: OptionShape[] = [];

    await Promise.all([
        searchUsers(search).then((response) => {
            response.forEach((user) => (user.value = `subject:${user.value}`));
            users = response;
        }),
        hcmsService.getClients().then((response) =>
            response.forEach((client) => {
                if (client.name.toLowerCase().includes(search)) {
                    clients.push({
                        label: client.name,
                        value: `client:${orgDto.organization?.hcmsApp.name}:${client.id}`
                    });
                }
            })
        )
    ]);

    return users.concat(clients);
};

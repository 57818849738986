import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { Filter } from "@civicplus/preamble-ui/lib/Utilities/odataHelper";

export const buildCustomSuppressionTypeFilter = (selectFilter: string[]): any => {
    const filter: any = {};

    if (!selectFilter || selectFilter.length < 1) {
        return filter;
    }

    selectFilter.forEach((fil) => {
        if (fil === "None") {
            filter["or"] = {
                ...filter["or"],
                and: {
                    isEmailSuppressed: { eq: false },
                    isSmsSuppressed: { eq: false }
                }
            };
        } else if (fil === "Both") {
            filter["or"] = {
                ...filter["or"],
                and: {
                    isEmailSuppressed: { eq: true },
                    isSmsSuppressed: { eq: true }
                }
            };
        } else if (fil === "Email") {
            filter["or"] = {
                ...filter["or"],
                isEmailSuppressed: { eq: true }
            };
        } else if (fil === "Sms") {
            filter["or"] = {
                ...filter["or"],
                isSmsSuppressed: { eq: true }
            };
        }
    });

    return filter;
};

export const buildCustomUserFilter = (selectedUsers: OptionShape[]): any => {
    const filter: any = {};

    if (!selectedUsers || selectedUsers.length < 1) {
        return filter;
    }

    let filterString = "(";

    selectedUsers.forEach((user, i) => {
        filterString += `contains(lastModifiedBy, \'${user.value?.toString().replace("subject:", "")}\')`;

        if (selectedUsers.length > i + 1) filterString += " or ";
    });

    filterString += ")";

    filter[""] = {
        "": filterString
    };

    return filter;
};

export const buildUserFilterFromOptionShape = (userFilter: OptionShape[], filterKey: string): Filter => {
    const ids = userFilter ? userFilter.map((user) => user.value?.toString() ?? "") : [];

    const filter: Filter = {};
    if (!ids || ids.length < 1) {
        return filter;
    }
    filter[filterKey] = {
        in: {
            type: "guid",
            value: ids.map((x) => `'${x}'`)
        }
    };

    return filter;
};

export type DateFilter = {
    [key: string]: {
        ge?: Date;
        le?: Date;
    };
};

export const buildDateFilter = (dateFilter: string[], filterKey: string): DateFilter => {
    const filter: DateFilter = {};
    if (!dateFilter || dateFilter.length < 1) {
        return filter;
    }

    let innerFilter: DateFilter[string] = {};
    if (dateFilter[0] && dateFilter[1]) {
        innerFilter = {
            ge: new Date(dateFilter[0]),
            le: new Date(dateFilter[1])
        };
    } else if (dateFilter[0]) {
        innerFilter = {
            ge: new Date(dateFilter[0])
        };
    } else if (dateFilter[1]) {
        innerFilter = {
            le: new Date(dateFilter[1])
        };
    }

    filter[filterKey] = innerFilter;

    return filter;
};

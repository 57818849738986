import { ApiService } from "../services/ApiService";
import {
    IGetOrganizationListResponse,
    IOrganizationService
} from "@civicplus/preamble-ui/lib/Services/OrgService/OrgService";
import IOdata from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Odata";
import { IUser } from "@civicplus/preamble-ui/lib/entities/baseEntities";
import { getStringError } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { isInRole } from "@civicplus/preamble-ui/lib/Utilities/User";
import { decodeToken } from "@civicplus/preamble-ui/lib/Utilities/TokenHelper";
import { User } from "oidc-client-ts";
import { Paged } from "../entities/Paged";
import { Organization } from "../entities/Organization";

export default class NotificationsOrganizationService implements IOrganizationService {
    private accessToken: string | undefined;
    private idToken: string | undefined;
    private readonly apiService: ApiService;

    constructor(apiService: ApiService, user?: User | null) {
        this.accessToken = user?.access_token ?? undefined;
        this.idToken = user?.id_token ?? undefined;
        this.apiService = apiService;
    }

    IsInRole(role: string): boolean {
        return isInRole(role, this.GetUser());
    }

    async GetOrganization(organizationName: string): Promise<CppOrganization> {
        try {
            return await this.apiService.get("organization");
        } catch (e) {
            const errorMessage = getStringError(e);
            throw new Error(`NotificationsOrganizationService - GetOrganization: ${errorMessage}`);
        }
    }

    async GetOrganizationList({ search }: IOdata): Promise<IGetOrganizationListResponse> {
        try {
            let orgs: Paged<Organization> = {
                items: [],
                count: 0
            };

            if (this.accessToken) {
                orgs = await this.apiService.getNoOrg(`searchOrgs/${search ?? ""}`);
            }

            return orgs;
        } catch (e) {
            const errorMessage = getStringError(e);
            throw new Error(`NotificationsOrganizationService - GetOrganizationList: ${errorMessage}`);
        }
    }

    async GetUserOrganizationList({ search }: IOdata): Promise<IGetOrganizationListResponse> {
        return this.GetOrganizationList({ search });
    }

    GetUser(): IUser | undefined {
        let user: IUser | undefined;

        if (this.idToken && this.accessToken) {
            const idPayload = decodeToken(this.idToken);
            const accessPayload = decodeToken(this.accessToken);
            const firstName = idPayload["given_name"];
            const lastName = idPayload["family_name"];

            if (firstName && lastName) {
                user = {
                    accessToken: this.accessToken,
                    email: idPayload.email,
                    firstName,
                    id: idPayload.sub,
                    idToken: this.idToken,
                    lastName,
                    role: accessPayload.role
                };
            }
        }

        return user;
    }
}

import { EnumDictionary } from "./ProductType";

export interface Suppression {
    id: string;
    to: string;
    errorCode: number;
    description: string;
    type: number;
    time: string;
    organization: string;
    client: string;
    from: string;
    source: SuppressionSource;
}

export enum SuppressionSource {
    Local,
    AWS,
    Bandwitdh
}

export const SourceLabels: EnumDictionary<string, string> = {
    [SuppressionSource.Local]: "Local",
    [SuppressionSource.AWS]: "AWS",
    [SuppressionSource.Bandwitdh]: "Bandwidth"
};

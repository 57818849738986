import React, { useCallback } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";

interface SimpleDialogProps {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    primaryAction: () => void;
    primaryBtnText: string;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
    title,
    children,
    isOpen,
    onClose,
    primaryAction,
    primaryBtnText
}) => {
    const onCloseDialog = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseDialog}
            title={title}
            actions={[
                <Button color="primary" onClick={primaryAction} key={`action-${title}-dialog`}>
                    {primaryBtnText}
                </Button>,

                <Button onClick={onCloseDialog} key={`cancel-${title}-dialog`}>
                    Cancel
                </Button>
            ]}
        >
            {children}
        </Dialog>
    );
};

import { HttpHelper } from "@civicplus/preamble-ui/lib/Utilities/HttpHelper";
import React, { useEffect } from "react";
import { bottle } from "../../provider/Bottle";

const SignOut: React.FC = () => {
    const userManager = bottle.container.UserManager;

    useEffect(() => {
        async function init() {
            const isAuthenticated = await userManager.isAuthenticatedAsync();

            // Only redirect if log out is from Notifications. Otherwise, the platform needs to call userManager.signOut() and doesn't need the redirect.
            if (isAuthenticated) {
                await userManager.signOut();
            } else {
                const orgId = HttpHelper.getQuery("state");
                bottle.container.WindowRouter.assign(`/${orgId || ""}`);
            }
        }
        init();
    }, [userManager]);

    return <div />;
};
export default SignOut;

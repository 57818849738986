import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link as RouterLinkWithTypes, useLocation, useNavigate, useParams } from "react-router-dom";
import FormBuilder from "@civicplus/preamble-ui/lib/FormBuilder";
import FormBuilderHelper, { verifyDuplicateFroala } from "../../../util/FormBuilder";
import ItemMeta from "@civicplus/preamble-ui/lib/ItemMeta";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import StickyRightBar from "@civicplus/preamble-ui/lib/StickyRightBar";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import { getUsersName } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { specialCharactersValidation } from "@civicplus/preamble-ui/lib/Validations";
import { emailValidation } from "../../../util/StringHelper";
import { OrganizationSettings } from "../../../entities/OrganizationSettings";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { TemplateFormBuilder } from "../templating/TemplateFormBuilder";
import { TemplateService } from "../../../services/TemplateService";
import { bottle } from "../../../provider/Bottle";
import { CategoriesSelector, CategoryDto } from "../shared/CategoriesSelector";
import { ProductTypeSelector, ProductTypeDto } from "../shared/ProductTypeSelector";
import { ProductsWithNotifications, ProductType } from "../../../entities/ProductType";
import { EmailTemplate, EmailTemplateType } from "../../../entities/Template";
import { SubscriptionList, SubscriptionListRequest } from "../../../entities/SubscriptionList";
import { SimpleDialog } from "../../../util/SimpleDialog";
import { usePrompt } from "../../../util/DirtyPrompt";
import { useSnackbar } from "notistack";

const RouterLink: any = RouterLinkWithTypes;

interface SubListViewProps {
    isEditingProp: boolean;
    changeState: any;
}

const SubscriptionListView: React.FC<SubListViewProps> = ({ isEditingProp, changeState }) => {
    const orgService = bottle.container.OrgService;
    const subscriptionListService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const templateService: TemplateService = bottle.container.TemplateService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    let $editor: any;

    const initialForm = {
        name: "",
        description: "",
        senderName: "",
        replyToEmailAddress: "",
        hcmsCategoryIds: [],
        productTypeList: [],
        header: "",
        footer: "",
        hideLabels: false,
        isListSMSEnabled: true,
        internalOnly: false,
        id: null
    };

    const [form, setForm] = useState<SubscriptionListRequest>(initialForm);
    const [initialName, setInitialName] = useState<string>("");
    const [list, setList] = useState<SubscriptionList | null>(null);
    const [categories, setCategories] = useState<string[]>([]);
    const [productTypes, setProductTypes] = useState<number[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingFields, setIsLoadingFields] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(isEditingProp !== null ? false : isEditingProp);
    const [dirty, setDirty] = useState<boolean>(false);
    const [hideLabels, setHideLabels] = useState<boolean>(false);
    const [formBuilderProps, setFormBuilderProps] = useState<any>({});
    const [isListSMSEnabled, setIsListSMSEnabled] = useState<boolean>(true);
    const [isInternalOnly, setIsInternalOnly] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [orgSettings, setOrgSettings] = useState<OrganizationSettings>();
    const [showCustomDirtyDialog, setShowCustomDirtyDialog] = useState<boolean>(false);
    const [buttonContainer, setButtonContainer] = useState<any>(null);

    const { pathname } = location;
    const isEditMode = id && pathname.endsWith("edit");

    const buttonContainerRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            setButtonContainer(node);
        }
    }, []);

    const load = useCallback(async () => {
        setIsLoadingFields(true);

        const list = isEditMode ? await subscriptionListService.getSubscriptionListById(id) : null;
        const listSMSEnabled = list ? list.isListSMSEnabled : true;

        if (isEditMode && !list) {
            setError(`Subscription list not found. Unable to modify.`);
            return;
        }
        if (!isEditMode) {
            setIsLoadingFields(false);
        }

        const results = await Promise.all([
            templateService.getHeader(list ? list.id : null),
            templateService.getFooter(list ? list.id : null)
        ]);

        const header = results[0];
        const footer = results[1];

        const orgSettings = settingsService.settings;

        const defaultForm: SubscriptionListRequest = {
            description: list ? list.description : "",
            footer: footer,
            hcmsCategoryIds: list ? list.hcmsCategoriesIds : [],
            header: header,
            hideLabels: list ? list.hideLabels : false,
            id: list ? list.id : null,
            internalOnly: list ? list.internalOnly : false,
            isListSMSEnabled: list ? list.isListSMSEnabled : true,
            name: list ? list.name : "",
            productTypeList: list ? list.productTypeList : [],
            replyToEmailAddress: list ? list.replyToEmailAddress : orgSettings.defaultListReplyToEmailAddress,
            senderName: list ? list.senderName : orgSettings.defaultListSenderName
        };

        setIsEditing(isEditMode ? isEditMode : false);
        setList(list);
        setInitialName(list ? list.name : "");
        setForm(defaultForm);
        setCategories(list ? list.hcmsCategoriesIds : []);
        setProductTypes(list ? list.productTypeList : []);
        setHideLabels(list ? list.hideLabels : false);
        setIsListSMSEnabled(listSMSEnabled);
        setOrgSettings(orgSettings);
        setIsInternalOnly(list ? list.internalOnly : false);
    }, [id, isEditMode, settingsService.settings, subscriptionListService, templateService]);

    useEffect(() => {
        async function init() {
            await load();
            setIsLoadingFields(false);
        }
        init();
    }, [load]);

    const froalaImageButtonClicked = useCallback(async (editor: any) => {
        $editor = editor;
        document.getElementById("fileInput")!.click();
    }, []);

    const deconstructData = useCallback(
        (_: any, dataIn: any) => {
            const data = Object.assign({}, dataIn);

            const deconstructedForm: any = FormBuilderHelper.deconstructData(form, data);

            deconstructedForm.id = deconstructedForm.id ? deconstructedForm.id : null;

            deconstructedForm.name = deconstructedForm.name ? deconstructedForm.name : "";
            deconstructedForm.description = deconstructedForm.description ? deconstructedForm.description : "";
            deconstructedForm.replyToEmailAddress = deconstructedForm.replyToEmailAddress
                ? deconstructedForm.replyToEmailAddress
                : "";
            deconstructedForm.senderName = deconstructedForm.senderName ? deconstructedForm.senderName : "";

            deconstructedForm.hideLabels = hideLabels;
            deconstructedForm.hcmsCategoryIds = categories;
            deconstructedForm.productTypeList = productTypes;
            deconstructedForm.isListSMSEnabled = isListSMSEnabled;
            deconstructedForm.internalOnly = isInternalOnly;

            setForm(deconstructedForm);

            return deconstructedForm;
        },
        [categories, form, hideLabels, isInternalOnly, isListSMSEnabled, productTypes]
    );

    const saveTemplates = useCallback(
        async (data: any, listId: number) => {
            const footer = data.footer || "".toString();
            const header = data.header || "".toString();

            const templateUpdates: EmailTemplate[] = [];

            if (footer !== form.footer) {
                templateUpdates.push(new EmailTemplate(EmailTemplateType.Footer, footer));
            }
            if (header !== form.header) {
                templateUpdates.push(new EmailTemplate(EmailTemplateType.Header, header));
            }
            if (templateUpdates.length > 0) {
                await templateService.save(templateUpdates, listId);
            }
        },
        [form.footer, form.header, templateService]
    );

    const onDirtyState = useCallback(
        (value: boolean) => {
            setDirty(value);
            if (changeState) {
                changeState(value);
            }
        },
        [changeState]
    );

    const onCancel = useCallback(
        (_event?: any, _data?: any, fromSave?: boolean) => {
            if (dirty) {
                setShowCustomDirtyDialog(true);
                return;
            }

            navigate(`/${(bottle.container.OrgService || {}).orgId}/admin/lists`);
        },
        [dirty, navigate]
    );

    const onSave = useCallback(
        async (event: any, data: any) => {
            const newForm = deconstructData(event, data);
            const listPayload = { ...newForm };
            delete listPayload.header;
            delete listPayload.footer;

            try {
                let listId;
                setSaving(true);
                setError(undefined);

                if (isEditing) {
                    await subscriptionListService.updateSubscriptionList(listPayload);
                    listId = list!.id;
                } else {
                    const list = await subscriptionListService.createSubscriptionList(listPayload);
                    listId = list.id;
                }

                setDirty(false);
                await saveTemplates(newForm, listId!);

                setSaving(false);
                setShowCustomDirtyDialog(false);
                onDirtyState(false);

                navigate(`/${(bottle.container.OrgService || {}).orgId}/admin/lists`);
            } catch (ex: any) {
                setError(ex.error);
                setSaving(false);
                return { skipToastNotification: true };
            }
        },
        [deconstructData, isEditing, list, navigate, onDirtyState, saveTemplates, subscriptionListService]
    );

    const buildSubscriptionListFormBuilderProps = useCallback(async () => {
        const makeField = (
            name: string,
            label: string,
            editor: string,
            placeholder: string,
            options: { helperText?: string; maxLength?: number; required?: boolean; [key: string]: any } = {},
            fieldType = "String"
        ) => {
            return {
                name,
                partioning: "invariant",
                value: form ? form[name] : "",
                properties: {
                    id: "subscriptionList" + name.charAt(0).toUpperCase() + name.slice(1),
                    disabled: saving,
                    isRequired: options.required ?? true,
                    isListField: false,
                    fieldType: fieldType,
                    label,
                    placeholder,
                    editor,
                    hints: options.helperText,
                    maxLength: options.maxLength
                }
            } as any;
        };

        let fields = [
            makeField("name", "Name", "Input", "Name", { maxLength: 100 }),
            makeField("description", "Description", "Input", "Description", { maxLength: 1000 }),
            makeField("senderName", "Sender Name", "Input", "Sender Name", { maxLength: 50, required: false }),
            makeField("replyToEmailAddress", "Reply-To Email Address", "Input", "Reply-To Email Address", {
                helperText: "This email address will be used when the user chooses to reply to an email notification.",
                maxLength: 100
            })
        ];

        const templateFormBuilder = new TemplateFormBuilder({
            defaultState: { header: form.header ?? "", footer: form.footer ?? "" },
            imageButtonClicked: froalaImageButtonClicked,
            onDirtyState: onDirtyState
        });

        const templateFormBuilderData = templateFormBuilder.fieldsAndDataOnly();

        fields = fields.concat(templateFormBuilderData.fields);

        const data = { ...FormBuilderHelper.constructData(form), ...templateFormBuilderData.data };

        const formProps = {
            ...FormBuilderHelper.makeFormBuilderProps({
                id: "subListFormBuilder",
                fields,
                data,
                onSave: onSave,
                onCancel: onCancel,
                buttonGroupAlignment: "stacked",
                onDirtyState: onDirtyState,
                customToolbarButtons: templateFormBuilder.customToolbarButtons()
            }),
            hideTagsField: true,
            fields: fields,
            froalaEvents: templateFormBuilder.froalaEvents(),
            fieldConfiguration: await templateFormBuilder.defaultFroalaEditorConfig(),
            customToolbarButtons: templateFormBuilder.customToolbarButtons(),
            onDirtyState: onDirtyState,
            customValidations: {
                senderName: [specialCharactersValidation],
                replyToEmailAddress: [emailValidation]
            }
        };

        setFormBuilderProps(formProps);
    }, [form, froalaImageButtonClicked, onCancel, onDirtyState, onSave, saving]);

    useEffect(() => {
        async function getFieldValues() {
            await buildSubscriptionListFormBuilderProps();
        }

        getFieldValues();
    }, [list, form, buildSubscriptionListFormBuilderProps]);

    useEffect(() => {
        if (formBuilderProps && formBuilderProps.fields) {
            setIsLoading(false);
        }
        verifyDuplicateFroala();
    }, [formBuilderProps]);

    const addFile = useCallback(
        async ($event: any) => {
            $editor.getEditor().image.upload($event.target.files);
        },
        [$editor]
    );

    const buildUserDetails = useCallback(() => {
        if (!isEditing || list == null) {
            return <div ref={buttonContainerRef} />;
        }

        const { createdBy, lastModifiedBy, lastModified, created } = list!;
        const createdDate = created && new Date(created);
        const modifiedDate = lastModified && new Date(lastModified);

        return (
            <>
                <div ref={buttonContainerRef} />
                <ItemMeta
                    createdBy={
                        createdBy && {
                            name: getUsersName(createdBy),
                            email: createdBy.email
                        }
                    }
                    modifiedBy={
                        lastModifiedBy && {
                            name: getUsersName(lastModifiedBy),
                            email: lastModifiedBy.email
                        }
                    }
                    createdDate={createdDate || undefined}
                    modifiedDate={modifiedDate || undefined}
                    id="meta"
                />
            </>
        );
    }, [buttonContainerRef, isEditing, list]);

    const includedApps = useMemo(() => {
        const orgApps = orgService.organization?.applications?.map((x: any) => x.productType);
        return orgApps ? ProductsWithNotifications.filter((p) => orgApps?.includes(p)) : [];
    }, [orgService.organization?.applications]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });

            if (error.includes("not found")) {
                navigate(`/${(bottle.container.OrgService || {}).orgId}/admin/lists`);
            }
        }
    }, [enqueueSnackbar, error, navigate]);

    usePrompt("Are you sure you want to discard changes?", dirty && !showCustomDirtyDialog && !saving);

    return (
        <>
            <StickyRightBar id="subscriptionListView-right-bar" rightBar={buildUserDetails()}>
                {isLoading || isLoadingFields ? (
                    <Loader verticallyCenter={true} />
                ) : (
                    <>
                        <Titlebar
                            id="titlebar"
                            title={isEditing ? `Modify ${initialName}` : "New Subscription List"}
                            breadcrumbs={[
                                <Link
                                    component={RouterLink}
                                    key="list"
                                    // @ts-ignore
                                    to={`/${orgService.orgId}/admin/lists`}
                                >
                                    Subscription Lists
                                </Link>
                            ]}
                        />

                        {formBuilderProps && formBuilderProps.fields && (
                            // TODO: Should we refactor this to not use any internal helpers?
                            <FormBuilder
                                key={JSON.stringify(formBuilderProps)}
                                {...formBuilderProps}
                                buttonContainer={buttonContainer}
                                onDirtyState={(value: boolean) => {
                                    onDirtyState(value);
                                }}
                            />
                        )}

                        {orgSettings?.isSMSEnabled && (
                            <div style={{ marginTop: 15 }}>
                                <Toggle
                                    label="Enable SMS"
                                    checked={isListSMSEnabled}
                                    onChange={() => setIsListSMSEnabled(!isListSMSEnabled)}
                                />
                                <p style={{ fontSize: "0.75rem", color: "rgba(0,0,0,.6)", marginTop: 3 }}>
                                    Enable to turn on SMS messages for this subscription list.
                                </p>
                            </div>
                        )}

                        {orgService.organization?.isOrgOwner && (
                            <div style={{ marginTop: 15 }}>
                                <Toggle
                                    label="Internal-Only"
                                    checked={isInternalOnly}
                                    onChange={() => {
                                        setIsInternalOnly(!isInternalOnly);
                                    }}
                                />
                                <p style={{ fontSize: "0.75rem", color: "rgba(0,0,0,.6)", marginTop: 3 }}>
                                    Enable to make this an internal-only list. This will remove the listing from the
                                    public-facing screen and only authenticated users associated to the Organization or
                                    imported users will be able to subscribe or unsubscribe.
                                </p>
                            </div>
                        )}

                        {includedApps.length > 0 && (
                            <ProductTypeSelector
                                isInDialog={false}
                                value={productTypes}
                                label="Products"
                                includedApps={includedApps}
                                onChange={(productTypes: ProductTypeDto[]): void => {
                                    !dirty && setDirty(true);
                                    setProductTypes(productTypes.map((x) => x.value));
                                }}
                                placeholder="Here you'll find available products for this organization"
                            />
                        )}

                        {productTypes?.includes(ProductType.HCMS) && (
                            <>
                                <CategoriesSelector
                                    value={categories}
                                    label="Categories"
                                    required={true}
                                    onChange={(categories: CategoryDto[]): void => {
                                        !dirty && setDirty(true);
                                        setCategories(categories.map((x) => x.id));
                                    }}
                                />

                                <div style={{ marginTop: 15 }}>
                                    <Toggle
                                        label="Hide field labels"
                                        checked={hideLabels}
                                        onChange={() => setHideLabels(!hideLabels)}
                                    />
                                    <p style={{ fontSize: "0.75rem", color: "rgba(0,0,0,.6)", marginTop: 3 }}>
                                        Enable to hide the content type field labels on all notifications for this
                                        subscription list.
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                )}
            </StickyRightBar>

            <input
                id="fileInput"
                name="fileInput"
                accept="image/jpeg, image/png, image/gif"
                type="file"
                hidden={true}
                onChange={($event: any) => addFile($event)}
            />

            <SimpleDialog
                title="Changes unsaved"
                isOpen={dirty && showCustomDirtyDialog}
                onClose={() => {
                    setShowCustomDirtyDialog(false);
                }}
                primaryAction={() => {
                    navigate(`/${(bottle.container.OrgService || {}).orgId}/admin/lists`);
                    setShowCustomDirtyDialog(false);
                }}
                primaryBtnText="Continue"
            >
                All changes unsaved will be lost. Are you sure you want to continue?
            </SimpleDialog>
        </>
    );
};

export default SubscriptionListView;

import React from "react";
import App from "./App";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container!);

root.render(
    <React.StrictMode>
        <ProductThemeProvider prefix="cp-notificationswrapper" product="notifications">
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </ProductThemeProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
    interface Window {
        dirty: boolean;
    }
}

export class DirtyStateManager {
    constructor() {
        window.dirty = false;
    }

    register() {
        window.addEventListener("beforeunload", this.onUnload);
    }

    onDirty(value: boolean) {
        window.dirty = value;
    }

    isDirty() {
        return window.dirty;
    }

    dispose() {
        window.removeEventListener("beforeunload", this.onUnload);
        window.dirty = false;
    }

    onUnload(event: any) {
        if (!window.dirty) {
            return;
        }
        event.returnValue = "You've made changes to content that haven't been saved. What would you like to do?";
        const response = window.confirm(event.returnValue);
        if (response) {
            this.onDirty(false);
        }
    }
}

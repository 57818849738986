import React from "react";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../provider/Bottle";

const Page403: React.FC = () => {
    const userManager = bottle.container.UserManager;

    return (
        <Typography variant="h6" align="center">
            You do not have access to this feature.{" "}
            <a href={userManager.getIdentityServerBaseUrl()}>Go back to dashboard.</a>
        </Typography>
    );
};

export default Page403;

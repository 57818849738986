import React, { PropsWithChildren, useEffect, useState } from "react";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import { Navigate } from "react-router-dom";
import { bottle } from "../../provider/Bottle";

const RestrictedRoute: React.FC<PropsWithChildren<{ superUser?: boolean }>> = ({ children, superUser = false }) => {
    const orgService = bottle.container.OrgService;

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [isOrgOwner, setIsOrgOwner] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [render, setRender] = useState<boolean>(false);

    useEffect(() => {
        async function init() {
            const isAuthenticated = await bottle.container.UserManager.isAuthenticatedAsync();
            const isSuperUser = await bottle.container.UserManager.isSuperUser();

            setIsAuthenticated(isAuthenticated);
            setIsSuperUser(isSuperUser);
        }
        init();

        setIsOrgOwner(orgService?.organization?.isOrgOwner ?? false);
        setHasAccess(orgService?.organization?.hasAccess ?? false);
        setRender(true);
    }, [orgService?.organization?.hasAccess, orgService?.organization?.isOrgOwner]);

    if (!render) {
        return <Loader verticallyCenter={true} />;
    }

    if (!isAuthenticated) {
        return (
            <Navigate
                to={{
                    pathname: "/CivicPlusPlatform/SignIn"
                }}
            />
        );
    }

    if (superUser && !isSuperUser) {
        return (
            <Navigate
                to={{
                    pathname: "/403"
                }}
            />
        );
    }

    if (!isOrgOwner && !hasAccess) {
        return (
            <Navigate
                to={{
                    pathname: "/403"
                }}
            />
        );
    }

    return children != null ? <>{children}</> : <></>;
};

export default RestrictedRoute;

import { OrgSubscriptions } from "../entities/OrgSubscriptions";
import { ApiService } from "./ApiService";

export class UnsubscribeService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getSubscriptions(hash: string): Promise<OrgSubscriptions> {
        return await this.apiService.getNoOrg(`unsubscribe/subscriptions/${hash}`);
    }

    public async unsubscribeFromLists(hash: string): Promise<any[]> {
        return await this.apiService.postNoOrg(`unsubscribe/lists/${hash}`, {});
    }

    public async unsubscribeFromAll(hash: string): Promise<any[]> {
        return await this.apiService.postNoOrg(`unsubscribe/all/${hash}`, {});
    }
}

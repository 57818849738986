import React, { useState, useEffect } from "react";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete/Autocomplete";
import sortBy from "lodash/sortBy";
import { bottle } from "../../../provider/Bottle";
import { HcmsService } from "../../../services/HcmsService";
import { HcmsCategory } from "../../../entities/Hcms";

export type CategoryDto = {
    id: string;
    name: string;
    version?: number;
    lastModified?: Date;
    lastModifiedBy?: string;
};

export interface CategoriesSelectorProps {
    value?: (HcmsCategory | string)[];
    onChange: (category: CategoryDto[]) => any;
    helperText?: string;
    className?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
}

export const CategoriesSelector: React.FC<CategoriesSelectorProps> = (props: CategoriesSelectorProps) => {
    const [categories, setCategories] = useState<CategoryDto[]>([]);
    const [value, setValue] = useState<CategoryDto[]>([]);

    useEffect((): void => {
        async function load(): Promise<void> {
            const service: HcmsService = bottle.container.HcmsService;
            const result: HcmsCategory[] = await service.getCategories();
            const selectedCategories = props.value
                ? props.value
                      .map((item) => {
                          return result.find((x) => x.id === (typeof item === "string" ? item : item?.id));
                      })
                      .filter((w) => !!w)
                      .filter((val, index, self) => self.indexOf(val) === index)
                : [];

            setCategories(sortBy(result, [(c: HcmsCategory): string => c.name.toLowerCase()]));
            setValue(sortBy(selectedCategories as CategoryDto[], [(c: CategoryDto): string => c.name.toLowerCase()]));
        }
        load();
    }, [props.value]);

    return (
        <Autocomplete
            id="list-categories"
            disabled={props.disabled}
            required={props.required}
            helperText={props.helperText}
            label={props.label}
            multiSelect={true}
            value={value.map((s) => ({ label: s.name, value: s.id }))}
            options={categories.map((s) => ({ label: s.name, value: s.id }))}
            onChange={(selected: OptionShape[] | undefined): void => {
                selected = selected ?? [];

                const selectedCategories = selected
                    .map((item) => categories.find((w) => w.id === item.value))
                    .filter((w) => w != null);

                setValue(selectedCategories as CategoryDto[]);
                props.onChange?.(selectedCategories as CategoryDto[]);
            }}
        />
    );
};

CategoriesSelector.defaultProps = {
    label: "Categories",
    value: []
};

import React, { useCallback, useEffect, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import FormBuilder from "@civicplus/preamble-ui/lib/FormBuilder";
import FormBuilderHelper from "../../../util/FormBuilder";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Link as RouterLink } from "react-router-dom";
import { TemplateFormBuilder } from "./TemplateFormBuilder";
import { TemplateService } from "../../../services/TemplateService";
import { EmailTemplate, EmailTemplateType } from "../../../entities/Template";
import { bottle } from "../../../provider/Bottle";
import { OrganizationService } from "../../../services/OrganizationService";
import { usePrompt } from "../../../util/DirtyPrompt";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import { useSnackbar } from "notistack";
import "./styles.css";

interface TemplateProps {
    changeState: any;
}

const Template: React.FC<TemplateProps> = ({ changeState }) => {
    const service: TemplateService = bottle.container.TemplateService;

    const orgService: OrganizationService = bottle.container.OrganizationService;
    const buttonContainer = React.createRef<HTMLDivElement>();

    let savedEditor: any;

    const initialFormDefinition = {
        footer: "",
        header: "",
        welcomeMessage: "",
        id: null
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formBuilderProps, setFormBuilderProps] = useState<any>({});
    const [form, setForm] = useState<any>(initialFormDefinition);
    const [initialForm, setInitialForm] = useState<any>(initialFormDefinition);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const prevError = usePreviousErrors(error);

    const froalaImageButtonClicked = useCallback(async (editor: any, identifier: any) => {
        savedEditor = editor;
        const input = document.getElementById("fileInput");
        input && input.click();
    }, []);

    const onSave = useCallback(
        async (event: any, data: any) => {
            data = FormBuilderHelper.deconstructData(form, data);
            const header = (data.header || "".toString()).trim();
            const footer = (data.footer || "".toString()).trim();
            const welcomeMessage = (data.welcomeMessage || "".toString()).trim();
            const templateUpdates: EmailTemplate[] = [];

            if (footer !== initialForm.footer) {
                templateUpdates.push(new EmailTemplate(EmailTemplateType.Footer, footer));
            }

            if (header !== initialForm.header) {
                templateUpdates.push(new EmailTemplate(EmailTemplateType.Header, header));
            }

            if (welcomeMessage !== initialForm.welcomeMessage) {
                templateUpdates.push(new EmailTemplate(EmailTemplateType.WelcomeMessage, welcomeMessage));
            }

            if (templateUpdates.length > 0) {
                await service.save(templateUpdates);
            }

            setForm({ header, footer, welcomeMessage });
            setDirty(false);
        },
        [form, initialForm.footer, initialForm.header, initialForm.welcomeMessage, service]
    );

    const onFormBuilderSave = useCallback(
        async (event: any, data: any) => {
            // return shouldShowConfirmModal ? await showConfirmModal(event, data) :
            return await onSave(event, data);
        },
        [onSave]
    );

    const onComplete = useCallback(
        (event?: any, data?: any) => {
            if (!data) {
                return;
            }

            if (isConfirmModalOpen) {
                setIsConfirmModalOpen(false);
                setIsLoading(false);
                return;
            }
        },
        [isConfirmModalOpen]
    );

    useEffect(() => {
        async function properInit() {
            try {
                const promises = [service.getHeader(), service.getFooter(), service.getWelcomeMessage()];
                const results = await Promise.all(promises);
                const header = results[0];
                const footer = results[1];
                const welcomeMessage = results[2];

                const builder = new TemplateFormBuilder({
                    onSave: onFormBuilderSave,
                    onComplete: onComplete,
                    imageButtonClicked: froalaImageButtonClicked,
                    defaultState: { header, footer, welcomeMessage },
                    onDirtyState: onDirtyState,
                    buttonContainer: buttonContainer.current,
                    includeWelcomeMessage: true
                });

                const formBuilderProps = await builder.build();

                setForm({ header, footer, welcomeMessage });
                setIsLoading(false);
                setFormBuilderProps(formBuilderProps);

                setInitialForm(Object.assign({}, form));
            } catch (e: any) {
                setError(e.error);
                setIsLoading(false);
            }
        }
        properInit();
    }, [froalaImageButtonClicked, onComplete, service]);

    const onDirtyState = useCallback(
        (value: boolean) => {
            setDirty(value);
            if (changeState) {
                changeState(value);
            }
        },
        [changeState]
    );

    const addFile = useCallback(
        async ($event: any) => {
            savedEditor.getEditor().image.upload($event.target.files);
            onDirtyState(false);
        },
        [onDirtyState, savedEditor]
    );

    const showConfirmModal = (event: any, data: any) => {
        return new Promise((resolve) => {
            setIsConfirmModalOpen(true);

            return (
                <Dialog
                    title="Delete"
                    open={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    actions={[
                        <Button
                            color="primary"
                            onClick={async () => {
                                try {
                                    await onSave(event, data);
                                    //@ts-ignore
                                    setIsConfirmModalOpen(false);
                                    setIsConfirmModalOpen(false);
                                    resolve({});
                                } catch (e: any) {
                                    setError(e.error);
                                }
                            }}
                            key="ok"
                        >
                            Ok
                        </Button>,
                        <Button
                            onClick={() => {
                                return resolve({ skipToastNotification: true });
                            }}
                            key="cancel"
                        >
                            Cancel
                        </Button>
                    ]}
                >
                    <Typography>
                        Header/Footer will be applied to new and non-customized lists. All custom list templates will
                        remain unchanged.
                    </Typography>
                </Dialog>
            );
        });
    };

    usePrompt("Are you sure you want to discard changes?", dirty);

    useEffect(() => {
        if (prevError && error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [enqueueSnackbar, error, prevError]);

    const build = () => {
        const form = () => {
            return (
                <>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <FormBuilder
                            {...formBuilderProps}
                            onDirtyState={(value: boolean) => {
                                onDirtyState(value);
                            }}
                        />
                    )}

                    <input
                        id="fileInput"
                        name="fileInput"
                        accept="image/jpeg, image/png, image/gif"
                        type="file"
                        hidden={true}
                        onChange={($event: any) => addFile($event)}
                    />
                </>
            );
        };

        return (
            <>
                <Titlebar
                    id="titlebar"
                    title="Templates"
                    description="Customize the default templates"
                    buttonRef={buttonContainer}
                    breadcrumbs={[
                        <Link
                            key="admin"
                            id="admin"
                            //@ts-ignore
                            to={`/${orgService.apiService._orgId}/admin`}
                            component={RouterLink}
                        >
                            Administration
                        </Link>
                    ]}
                />

                {form()}
            </>
        );
    };

    return <>{build()}</>;
};

export default Template;

import { SubscriptionList } from "./SubscriptionList";

export interface Subscriber extends User {
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    subscriptionDate?: string;
    type: SubscriptionType;
    isPending: boolean;
    subscriptions: Subscription[];
    isSmsSuppressed?: boolean;
    smsSuppressionDate?: string;
    suppressionType?: SuppressionType;
}

export interface BaseUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface User extends BaseUser {
    phoneNumber?: string;
    isEmailSuppressed?: boolean;
    isValidated?: boolean;
    sendWelcomeEmail?: boolean;
    emailSuppressionDate?: string;
}

export interface Subscription {
    listId?: number;
    userId?: string;
    user: User;
    list?: SubscriptionList;
    type: SubscriptionType;
    date?: string;
}

export interface Subscriptions {
    [groupId: string]: SubscriptionType;
}

export enum SubscriptionType {
    Email,
    Sms,
    EmailAndSms,
    None
}

export enum SuppressionType {
    Email,
    Sms,
    Both,
    None
}

import React, { useEffect } from "react";
import { bottle } from "../../provider/Bottle";

const SignInCallback: React.FC = () => {
    useEffect(() => {
        async function init() {
            await bottle.container.UserManager.signInRedirectCallbackAsync();
        }

        init();
    }, []);

    return <div />;
};

export default SignInCallback;

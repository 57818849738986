export interface InitiateImportResult {
    id: string;
}

export interface ImportStatusResult {
    id: string;
    status: ImportStatus;
    errors: Record<number, string>;
    successCount: number;
    failedCount: number;
    unvalidatedUserCount: number;
    removedCount: number;
    totalCount: number;
    processedCount: number;
}

export enum ImportStatus {
    Processing = "Processing",
    Completed = "Completed",
    Errored = "Errored"
}

import { Paged } from "../entities/Paged";
import { Permission } from "../entities/Permission";
import { ApiService } from "./ApiService";

export class PermissionsService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getPermissions(page = 0, rowsPerPage = 25): Promise<Paged<Permission>> {
        return await this.apiService.get(
            `permissions?$orderby=groupName asc&$count=true&$skip=${page * rowsPerPage}&$top=${rowsPerPage}`
        );
    }

    public async updatePermission(permission: Permission): Promise<Permission> {
        return await this.apiService.post("permissions", permission);
    }

    public async syncGroups(): Promise<unknown> {
        return await this.apiService.get("permissions/syncGroups");
    }
}

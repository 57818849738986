import React, { useEffect, useState, useCallback } from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import MetricCard from "@civicplus/preamble-ui/lib/MetricCard";
import SvgIcon from "@mui/material/SvgIcon";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { bottle } from "../../../provider/Bottle";
import { EnhancedDateRange } from "../../../components/EnhancedDateRange/EnhancedDateRange";
import { mdiEmailArrowRightOutline, mdiMessageProcessingOutline, mdiCalendarClock } from "@mdi/js";
import { MessageService } from "../../../services/MessageService";
import { MessageHistory, MessageType } from "../../../entities/Message";

export const Dashboard: React.FC = () => {
    const messageService: MessageService = bottle.container.MessageService;

    const defaultStartRangeInMonths = 1; // 1 month
    const currentDate = new Date();
    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - defaultStartRangeInMonths);

    const [emailsCount, setEmailsCount] = useState<number>(0);
    const [smsCount, setSmsCount] = useState<number>(0);
    const [messageHistory, setMessageHistory] = useState<any[]>([]);
    const [messagesAreaChart, setMessagesAreaChart] = useState<any[]>([]);
    const [dateRangeStart, setDateRangeStart] = useState<Date>(oneMonthAgo);
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(currentDate);

    // Total Messages Sent Chart
    // const messagesChartData = [{ name: "Messages Sent", value: 50000 }];

    useEffect(() => {
        async function initAsync() {
            const results = await Promise.all([
                await messageService.getSentMessages(MessageType.Email, dateRangeStart, dateRangeEnd),
                await messageService.getSentMessages(MessageType.Sms, dateRangeStart, dateRangeEnd),
                await messageService.getSentMessagesHistory(dateRangeStart, dateRangeEnd)
            ]);

            setEmailsCount(getMessageCount(results[0].items));
            setSmsCount(getMessageCount(results[1].items));
            setMessageHistory(results[2]);
        }

        initAsync();
    }, [messageService, dateRangeStart, dateRangeEnd]);

    useEffect(() => {
        async function setMessagesChart() {
            const results: any[] = [];
            for (const [date, messages] of Object.entries(messageHistory)) {
                results.push({
                    date: date,
                    email: messages.find((m: any) => m.type === MessageType.Email)?.sent ?? 0,
                    sms: messages.find((m: any) => m.type === MessageType.Sms)?.sent ?? 0
                });
            }
            setMessagesAreaChart(results);
        }

        setMessagesChart();
    }, [messageHistory]);

    function getMessageCount(messages: MessageHistory[]) {
        let count = 0;
        messages?.forEach(function (message) {
            count += message.count;
        });
        return count;
    }

    const onDateRangeChange = useCallback((startDate: Date, endDate: Date) => {
        setDateRangeStart(startDate);
        setDateRangeEnd(endDate);
    }, []);

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid xs={12} sm={6}>
                    <Titlebar id="titlebar" title="My Dashboard" />
                </Grid>

                <Grid xs={12} sm={6}>
                    <EnhancedDateRange
                        currentDate={currentDate}
                        defaultStartRangeInMonths={defaultStartRangeInMonths}
                        maxRangeInYears={1}
                        maxNumberOfYearsToSearch={3}
                        onDateRangeChange={onDateRangeChange}
                    />
                </Grid>

                <Grid xs={12} sm={6} lg={4} xl={3}>
                    <Grid container={true} spacing={3}>
                        <Grid xs={12}>
                            <div style={{ marginRight: "6%", marginBottom: "6%" }}>
                                <MetricCard
                                    id="metric-emails"
                                    title="Emails Sent"
                                    content={emailsCount.toLocaleString()}
                                    measure="emails"
                                    icon={
                                        <SvgIcon color="inherit">
                                            <path d={mdiEmailArrowRightOutline} />
                                        </SvgIcon>
                                    }
                                />
                            </div>
                        </Grid>

                        <Grid xs={12}>
                            <div style={{ marginRight: "6%", marginBottom: "6%" }}>
                                <MetricCard
                                    id="metric-sms"
                                    title="SMS Messages Sent"
                                    content={smsCount.toLocaleString()}
                                    measure="messages"
                                    icon={
                                        <SvgIcon color="inherit">
                                            <path d={mdiMessageProcessingOutline} />
                                        </SvgIcon>
                                    }
                                />
                            </div>
                        </Grid>

                        {/* Commenting out until we add message purchasing functionality.
                        <Grid xs={12}>
                            <MetricCard
                                icon={
                                    <SvgIcon color="inherit">
                                        <path d={mdiTrayFull} />
                                    </SvgIcon>
                                }
                                id="metric-total"
                                title="Total Messages Sent"
                                content={
                                    <>
                                        <ResponsiveContainer width="100%" minHeight={140} height="100%">
                                            <RadialBarChart
                                                startAngle={180}
                                                endAngle={0}
                                                innerRadius={90}
                                                outerRadius={140}
                                                data={messagesChartData}
                                                cy={140}
                                            >
                                                <PolarAngleAxis
                                                    type="number"
                                                    // The domain prop is needed to get the bar to scale as percentage
                                                    // It seems to be a bug that the prop is not exposed via TypeScript:
                                                    // https://github.com/recharts/recharts/issues/2203
                                                    // @ts-ignore
                                                    domain={[0, totalMessagesPurchased]}
                                                    angleAxisId={0}
                                                    tick={false}
                                                />
                                                <RadialBar
                                                    isAnimationActive={false}
                                                    fill="#455469"
                                                    background
                                                    angleAxisId={0}
                                                    dataKey="value"
                                                />
                                            </RadialBarChart>
                                        </ResponsiveContainer>
                                        {totalMessagesUsed.toLocaleString()}
                                    </>
                                }
                                measure="messages"
                                label={`of ${totalMessagesPurchased.toLocaleString()} purchased`}
                            />
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid xs={12} sm={6} lg={8} xl={9}>
                    <Grid container={true} spacing={3}>
                        <Grid xs={12}>
                            <MetricCard
                                id="metric-total"
                                title="Messaging History"
                                icon={
                                    <SvgIcon color="inherit">
                                        <path d={mdiCalendarClock} />
                                    </SvgIcon>
                                }
                                label={
                                    <ResponsiveContainer minHeight={544}>
                                        <AreaChart
                                            width={500}
                                            height={400}
                                            data={messagesAreaChart}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />

                                            <XAxis dataKey="date" />

                                            <YAxis
                                                label={{ value: "Messages Sent", angle: -90, position: "insideLeft" }}
                                            />
                                            <Tooltip />

                                            <Legend verticalAlign="top" height={36} />

                                            <Area
                                                type="monotone"
                                                dataKey="sms"
                                                stackId="1"
                                                stroke="#1779B0"
                                                fill="#1779B0"
                                                activeDot={{ r: 8 }}
                                            />

                                            <Area
                                                type="monotone"
                                                dataKey="email"
                                                stackId="1"
                                                stroke="#167c6a"
                                                fill="#167c6a"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

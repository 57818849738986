import Papa from "papaparse";
import { Subscription, SubscriptionType } from "../entities/Subscriber";
import { ApiService } from "./ApiService";
import { InitiateImportResult, ImportStatusResult } from "../entities/SubscriberImport";
import FileHelper from "../util/FileHelper";
import { UserManager } from "./UserManager";
import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";

export type PapaParseResult = {
    data: any[];
    errors: string[];
    meta: { fields: string[] };
};

export type ParsedImportCsv = {
    firstNameField: string;
    lastNameField: string;
    emailField: string;
    isValidated: string; // todo change from string here
    phoneNumberField: string;
    sendWelcomeEmail: string; // todo change from string here
    subscriptionTypeField: string;
    errors: string[];
    data: Subscription[];
};

export class SubscriberImportService {
    private readonly apiService: ApiService;
    private readonly userManager: UserManager;

    constructor(apiService: ApiService, userManager: UserManager) {
        this.apiService = apiService;
        this.userManager = userManager;
    }

    public getTemplateFile = async (): Promise<void> => {
        const fileName = "notificationsImportTemplate.csv";
        let header = "First Name,Last Name,Email,Phone Number,Subscription Type";
        if (await this.userManager.isSuperUser()) {
            header += ",Is Validated,Send Welcome Email";
        }
        const blob = new Blob([header], {
            type: "text/plain;charset=utf-8"
        });

        const isEdge = navigator.userAgent.match(/Edge/g);

        if (isEdge) {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(link);
        } else {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = `${fileName}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    public async parseFile(file: File): Promise<ParsedImportCsv> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<ParsedImportCsv>(async (resolve) => {
            const config: any = {
                header: true,
                skipEmptyLines: true,
                complete: async (result: any) => {
                    resolve(this.parseCsv(result));
                }
            };

            const text = (await FileHelper.readText(file)) as string;
            Papa.parse(text, config);
        });
    }

    public async initiateImport(
        subscriptions: Subscription[],
        listId: number,
        replaceExisting = false
    ): Promise<InitiateImportResult> {
        return await this.apiService.post(`${listId}/subscriber-import`, {
            subscriptions,
            listId,
            replaceExisting
        });
    }

    public async getImportStatus(listId: number, importId: string): Promise<ImportStatusResult> {
        return await this.apiService.get(`${listId}/subscriber-import/${importId}`);
    }

    private async parseCsv(result: PapaParseResult): Promise<ParsedImportCsv> {
        const fields = result.meta.fields;
        const mapped = fields.map((f) => ({ name: f, parsed: f.toLowerCase().replace(/ /g, "") }));
        const get = (field: string): string => {
            const result = mapped.find((m) => m.parsed === field);
            return result ? result.name : "";
        };
        const isSuperUser = await this.userManager.isSuperUser();

        const response: ParsedImportCsv = {
            errors: result.errors as string[],
            firstNameField: get("firstname"),
            lastNameField: get("lastname"),
            phoneNumberField: get("phonenumber"),
            emailField: get("email"),
            subscriptionTypeField: get("subscriptiontype"),
            isValidated: isSuperUser ? get("isvalidated") : "false",
            sendWelcomeEmail: isSuperUser ? get("sendwelcomeemail") : "true",
            data: []
        };
        if (!response.firstNameField) {
            response.errors.push("Required first name column was not found.");
        }
        if (!response.lastNameField) {
            response.errors.push("Required last name column was not found.");
        }
        if (!response.emailField) {
            response.errors.push("Required email column was not found.");
        }
        if (!response.subscriptionTypeField) {
            response.errors.push("Required subscription type column was not found.");
        }

        function getBooleanOrDefault(rawValue: string, defaultValue: boolean): boolean {
            if (!isNullOrWhiteSpace(rawValue)) {
                if (rawValue.trim() === "") {
                    return defaultValue;
                }

                const isTrueIndex = ["true", "1", "yes"].indexOf(rawValue.toLowerCase().trim());
                if (isTrueIndex > -1) {
                    return true;
                }

                const isFalseIndex = ["false", "0", "no"].indexOf(rawValue.toLowerCase().trim());
                return isFalseIndex > -1 ? false : defaultValue;
            }
            return defaultValue;
        }

        if (response.errors.length === 0) {
            response.data = result.data.map((r) => {
                const type = r[response.subscriptionTypeField].toLowerCase().trim();

                const subscription: Subscription = {
                    user: {
                        id: "",
                        firstName: r[response.firstNameField].trim(),
                        lastName: r[response.lastNameField].trim(),
                        email: r[response.emailField].trim(),
                        phoneNumber: (r[response.phoneNumberField] || "").trim(),
                        isValidated: getBooleanOrDefault(r[response.isValidated], false),
                        sendWelcomeEmail: getBooleanOrDefault(r[response.sendWelcomeEmail], true)
                    },
                    type:
                        type === "both"
                            ? SubscriptionType.EmailAndSms
                            : type === "sms"
                              ? SubscriptionType.Sms
                              : SubscriptionType.Email
                };
                return subscription;
            });
        }
        return response;
    }
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export enum ProductType {
    CivicPlus = 0,
    CivicEngageCentral = 1,
    CivicReady = 2,
    CivicHr = 3,
    CivicRec = 5,
    HCMS = 6,
    CpPay = 7,
    CivicEngageEvolve = 8,
    CivicClerk = 9,
    Notifications = 10,
    CpConnect = 11,
    CivicPlusIntegrationHub = 14,
    SeeClickFix = 15,
    ArchiveSocial = 16,
    CivicEngageOpen = 17,
    CivicGov = 18,
    CivicOptimize = 19,
    CivicPlusOrganizations = 20,
    CivicPlusPortal = 21,
    Monsido = 22,
    MunicodeMeetings = 23,
    MunicodeNEXT = 24,
    NextRequest = 25
}

export const ProductsWithNotifications: Array<number> = [
    ProductType.HCMS,
    ProductType.CivicClerk,
    ProductType.Notifications,
    ProductType.CivicEngageOpen
];

export const ProductTypeLabels: EnumDictionary<string, string> = {
    [ProductType.CivicPlus]: "CivicPlus",
    [ProductType.CivicEngageCentral]: "CivicEngage Central",
    [ProductType.CivicReady]: "CivicReady",
    [ProductType.CivicHr]: "CivicHr",
    [ProductType.CivicRec]: "CivicRec",
    [ProductType.HCMS]: "HCMS",
    [ProductType.CpPay]: "CivicPlus Pay",
    [ProductType.CivicEngageEvolve]: "CivicEngage Evolve",
    [ProductType.CivicClerk]: "CivicClerk",
    [ProductType.Notifications]: "CivicPlus Notifications",
    [ProductType.CpConnect]: "CP Connect",
    [ProductType.CivicPlusIntegrationHub]: "CivicPlus Integration Hub",
    [ProductType.SeeClickFix]: "SeeClickFix",
    [ProductType.ArchiveSocial]: "ArchiveSocial",
    [ProductType.CivicEngageOpen]: "CivicEngage Open",
    [ProductType.CivicGov]: "CivicGov",
    [ProductType.CivicOptimize]: "CivicOptimize",
    [ProductType.CivicPlusOrganizations]: "CivicPlus Organizations",
    [ProductType.CivicPlusPortal]: "CivicPlus Portal",
    [ProductType.Monsido]: "Monsido",
    [ProductType.MunicodeMeetings]: "Municode Meetings",
    [ProductType.MunicodeNEXT]: "MunicodeNEXT",
    [ProductType.NextRequest]: "NextRequest"
};

export enum CppOrganizationProductType {
    CivicPlus = 0,
    CivicEngageCentral = 1,
    CivicReady = 2,
    CivicHr = 3,
    CivicRec = 5,
    HCMS = 6,
    CpPay = 7,
    CivicEngageEvolve = 8,
    CivicClerk = 9,
    Notifications = 10,
    CpConnect = 11,
    CivicPlusIntegrationHub = 14,
    SeeClickFix = 15,
    ArchiveSocial = 16,
    CivicEngageOpen = 17,
    CivicGov = 18,
    CivicOptimize = 19,
    CivicPlusOrganizations = 20,
    CivicPlusPortal = 21,
    Monsido = 22,
    MunicodeMeetings = 23,
    MunicodeNEXT = 24,
    NextRequest = 25
}

export enum ProductTypeWithLabels {
    CivicEngageCentral = "CivicEngageCentral",
    CivicReady = "CivicReady",
    CivicRec = "CivicRec",
    HCMS = "HCMS",
    CpPay = "CpPay",
    CivicEngageEvolve = "CivicEngageEvolve",
    CivicClerk = "CivicClerk",
    Notifications = "Notifications",
    CivicPlusIntegrationHub = "CivicPlusIntegrationHub",
    SeeClickFix = "SeeClickFix",
    ArchiveSocial = "ArchiveSocial",
    CivicEngageOpen = "CivicEngageOpen",
    CivicGov = "CivicGov",
    CivicOptimize = "CivicOptimize",
    CivicPlusOrganizations = "CivicPlusOrganizations",
    CivicPlusPortal = "CivicPlusPortal",
    Monsido = "Monsido",
    MunicodeMeetings = "MunicodeMeetings",
    MunicodeNEXT = "MunicodeNEXT",
    NextRequest = "NextRequest"
}

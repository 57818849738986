import React, { useCallback, useEffect, useState } from "react";
import FormBuilder from "@civicplus/preamble-ui/lib/FormBuilder";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import FormBuilderHelper from "../../../../util/FormBuilder";

interface GetPhoneProps {
    callback: (phone: string) => any;
    onCancel: () => any;
}

const GetPhoneNumber: React.FC<GetPhoneProps> = ({ callback, onCancel }) => {
    const [cancelActionCalled, setCancelActionCalled] = useState<boolean>(false);

    const onSave = useCallback(
        async (e: any, result: any): Promise<any> => {
            e.preventDefault();
            let phoneNumber = result.phoneNumber;
            phoneNumber = phoneNumber.replace(/\D/g, "");

            if (phoneNumber.length < 10) {
                return onCancel();
            }

            return { skipToastNotification: true, number: phoneNumber };
        },
        [onCancel]
    );

    const onComplete = useCallback(
        (data: any) => {
            if (Object.keys(data).length === 0 || data.error || cancelActionCalled) {
                return onCancel();
            }

            const number = data.phoneNumber.replace(/\D/g, "");

            if (number.length < 10) {
                return onCancel();
            }

            callback(data != null ? number : null);
        },
        [callback, cancelActionCalled, onCancel]
    );

    const onCancelLocal = useCallback(() => {
        setCancelActionCalled(true);
        onCancel();
    }, [onCancel]);

    const field = {
        name: "phoneNumber",
        partioning: "invariant",
        properties: {
            id: "getPhoneNumber",
            disabled: false,
            isRequired: true,
            isListField: false,
            pattern: "^[0-9]{3}-[0-9]{3}-[0-9]{4}$",
            patternMessage: "This field must be a valid phone number.",
            inlineEditable: false,
            fieldType: "String",
            mask: "PhoneNumber",
            editor: "MaskedInput",
            label: "Phone Number",
            value: ""
        }
    } as any;

    const formBuilderProps = {
        fields: [field],
        ...FormBuilderHelper.makeFormBuilderProps({
            id: "phoneNumberFormBuilder",
            fields: [field],
            data: {},
            onSave: onSave,
            onComplete: onComplete,
            onCancel: onCancelLocal
        })
    };

    useEffect(() => {
        const dialogPhoneNumber = document.querySelector("#phoneNumberFormBuilder");

        if (dialogPhoneNumber) {
            dialogPhoneNumber.addEventListener("keydown", (e: any) => {
                if (e.keyCode === 13) {
                    e.preventDefault();

                    const inputPhone = document.getElementById("phoneNumberFormBuilder-phoneNumber") as any;
                    if (inputPhone) {
                        const inputValue = inputPhone.value.replace(/\D/g, "");
                        const finalValue = inputValue.length === 10 ? inputValue : null;

                        onSave(e, { phoneNumber: finalValue });
                        onComplete({ phoneNumber: finalValue });
                    }
                }
            });
        }
    }, [onCancelLocal, onComplete, onSave]);

    return (
        <>
            <Typography>Enter your phone number to subscribe by SMS.</Typography>
            <FormBuilder {...formBuilderProps} buttonContainer={undefined} />
        </>
    );
};

export default GetPhoneNumber;

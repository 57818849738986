import { HistoryLogDto } from "../entities/History";
import { ODataQuery } from "../entities/ODataQuery";
import { Paged } from "../entities/Paged";
import { ApiService } from "./ApiService";

export class HistoryLogService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getHistory(
        page = 0,
        rowsPerPage = 25,
        sortColumn = "actionDate",
        sortDirection = "desc",
        filter: any = {}
    ): Promise<Paged<HistoryLogDto>> {
        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });
        return await this.apiService.getOdata("historylog", odata);
    }

    public async getExport(): Promise<any> {
        await this.apiService.getFile("historylog/export", `${this.apiService.orgId}-HistoryLog.csv`);
    }
}

import { AxiosRequestConfig } from "axios";
import { HcmsCategory } from "../entities/Hcms";
import { ODataQuery } from "../entities/ODataQuery";
import { NotificationDetails, PendingMessageContainer } from "../entities/PendingMessage";
import { ApiService } from "./ApiService";

export class HcmsService {
    private readonly apiService: ApiService;
    private hcmsToken: string | null = null;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getCategories(): Promise<HcmsCategory[]> {
        return await this.apiService.get(`hcms/app/categories`, await this.buildRequestConfig());
    }

    public async getClients(): Promise<HcmsCategory[]> {
        return await this.apiService.get(`hcms/app/clients`, await this.buildRequestConfig());
    }

    public async getHcmsToken(userToken: string): Promise<{ token: string }> {
        return await this.apiService.post(`hcms/token?userToken=${userToken}`, {});
    }

    public async getPendingMessages(
        page = 0,
        rowsPerPage = 25,
        listId: string | null,
        sortColumn = "dateSent",
        sortDirection = "asc",
        filter?: any
    ): Promise<PendingMessageContainer> {
        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });
        return await this.apiService.getOdata(
            `messages/pending/${listId ?? ""}`,
            odata,
            await this.buildRequestConfig()
        );
    }

    public async updateNotificationDetails(
        itemId: string,
        contentType: string,
        notificationDetails: NotificationDetails,
        isAsset: boolean
    ): Promise<any> {
        return await this.apiService.put(
            `notification?itemId=${itemId}&contentType=${contentType}&isAsset=${isAsset}`,
            notificationDetails,
            await this.buildRequestConfig()
        );
    }

    public async getContentType(contentType: string): Promise<any> {
        if (contentType) {
            return await this.apiService.get(
                `hcms/app/content-type?contentTypeId=${contentType}`,
                await this.buildRequestConfig()
            );
        }
        return null;
    }

    private async buildRequestConfig(): Promise<AxiosRequestConfig> {
        if (!this.hcmsToken) {
            const user = await this.apiService.getUser();
            if (!user) {
                return {};
            }

            const result = await this.getHcmsToken(user.access_token);
            this.hcmsToken = `${user.token_type} ${result.token}`;
        }

        return { headers: { HcmsAuthorization: this.hcmsToken } };
    }
}

import { NotificationDetails } from "./PendingMessage";
import { Subscriber } from "./Subscriber";

export interface Message {
    subject: string;
    body: string;
    sms: boolean;
    mail: boolean;
    listId: string;
    [key: string]: any;
}

export enum UpdateAction {
    Approve = "approve",
    Reject = "reject"
}

export enum TemplateType {
    AssetCreated,
    AssetModified,
    ContentCreated,
    ContentModified,
    Unknown
}

export interface MessageHistory {
    id: string;
    listId?: number;
    topicId?: number;
    subject: string;
    sender?: Subscriber;
    dateSent?: string;
    fromName?: string;
    notificationDetails?: NotificationDetails;
    templateValuesJson?: string;
    templateType?: TemplateType;
    templateVersion?: number;
    headerVersion?: number;
    footerVersion?: number;
    emailContent?: string;
    smsText?: string;
    lists?: BasicList[];
    isPendingMessage?: boolean;
    contentTypeId: string;
    listIds: number[] | null;
    count: number;
    tag?: string;
    to: string;
    emailCount?: number;
    smsCount?: number;
}

export enum MessageType {
    Sms = 0,
    Email = 1
}

export interface BasicList {
    id: number;
    name: string;
}

export interface MessageEvent {
    id: number;
    messageId: string;
    tag: string;
    time: Date;
    to: string;
    type: string; // will need converted?
    organization: string;
    client: string;
}

export interface EmailEvent extends MessageEvent {
    errorMessage: string;
    subject: string;
    externalUserId: string;
    data: string;
}

import React, { useState, useReducer, useEffect, ReactElement } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Card from "@civicplus/preamble-ui/lib/Card";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import FileUpload from "@civicplus/preamble-ui/lib/FileUpload";
import FormControlLabel from "@civicplus/preamble-ui/lib/FormControlLabel";
import List from "@civicplus/preamble-ui/lib/List";
import ListItem from "@civicplus/preamble-ui/lib/ListItem";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../provider/Bottle";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { ParsedImportCsv, SubscriberImportService } from "../../services/SubscriberImportService";
import { ImportStatus, ImportStatusResult, InitiateImportResult } from "../../entities/SubscriberImport";

const useStyles = makeStyles(() => ({
    exampleFileBtn: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        border: "none",
        background: "none",
        padding: 0,
        fontSize: "1rem",
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif"
    }
}));

enum ImportActionType {
    StartParse,
    ParseError,
    ParseSuccess,
    StartUpload,
    UploadError,
    UploadSuccess,
    StartPoll,
    PollSuccess,
    PollError,
    Reset
}

interface ImportSubscribersProps {
    orgId: string;
    importId?: string;
    selectedListId: number;
    // If given, we'll direct the browser to the import status URL after uploading a file to import.
    replaceRoute?: (routePath: string) => void;
    onUploaded?: (result: InitiateImportResult) => void;
    onClose?: () => any;
}

export const ImportSubscribers: React.FC<ImportSubscribersProps> = (props) => {
    const [importState, importDispatch] = useImportState(5000, props.selectedListId, props.importId);
    const onClose = (): void => {
        props.onClose && props.onClose();
        props.replaceRoute && props.replaceRoute(getSubscribersListUrl(props.orgId, props.selectedListId));
    };

    let dialogBody: ReactElement;
    let dialogTitle = "";

    if (importState.importStatus) {
        if (importState.importStatus.status === ImportStatus.Completed) {
            dialogTitle = "Import Status: Completed";
        } else if (importState.importStatus.status === ImportStatus.Errored) {
            dialogTitle = "Import Status: Failed";
        } else if (importState.importStatus.status === ImportStatus.Processing) {
            dialogTitle = `Import Status: Processing ${importState.importStatus?.processedCount} of ${importState.importStatus?.totalCount}`;
        }

        dialogBody = (
            <ImportStatusMessage
                orgId={props.orgId}
                listId={props.selectedListId}
                importStatus={importState.importStatus}
                isPollingForStatus={importState.isPollingForStatus}
                onImportMore={(): void => {
                    importDispatch({ type: ImportActionType.Reset });
                    props.replaceRoute && props.replaceRoute(getSubscribersListUrl(props.orgId, props.selectedListId));
                }}
                onDoneImporting={onClose}
            />
        );
    } else if (importState.isUploading || importState.isPollingForStatus) {
        dialogBody = <Waiting importState={importState} totalToImport={importState.parsedFile?.data?.length} />;
    } else {
        dialogTitle = "Import Subscribers";
        dialogBody = (
            <UploadForm
                importState={importState}
                onSelectFile={async (files: any) => {
                    return handleSelectFile(importDispatch, files[0]);
                }}
                onUpload={async (parsedFile: ParsedImportCsv, replaceExisting: boolean) => {
                    await handleUpload(
                        importDispatch,
                        props.selectedListId,
                        parsedFile,
                        replaceExisting,
                        (result: InitiateImportResult): void => {
                            props.replaceRoute &&
                                props.replaceRoute(getSubscribersListUrl(props.orgId, props.selectedListId, result.id));
                            props.onUploaded && props.onUploaded(result);
                        }
                    );
                }}
            />
        );
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            id={`import-for-${props.selectedListId}`}
            title={dialogTitle}
            fullWidth={true}
            maxWidth="md"
        >
            {dialogBody}
        </Dialog>
    );
};

function useImportState(
    pollRate: number,
    listId: number,
    importId: string | undefined
): [ImportState, (action: any) => void] {
    const [importState, importDispatch] = useReducer<(state: ImportState, action: any) => ImportState>(importReducer, {
        ...initialImportState,
        isPollingForStatus: Boolean(importId),
        importId
    });

    const shouldPoll =
        importState.importId &&
        (!importState.importStatus || importState.importStatus.status === ImportStatus.Processing);

    useEffect(() => {
        let canUpdateState = true;

        const loadStatus = async () => {
            if (!shouldPoll) {
                return;
            }

            if (canUpdateState) {
                importDispatch({ type: ImportActionType.StartPoll });
            }

            const importService: SubscriberImportService = bottle.container.SubscriberImportService;
            try {
                const importStatusResult = await importService.getImportStatus(listId, importState.importId as string);

                if (canUpdateState) {
                    importDispatch({ type: ImportActionType.PollSuccess, importStatus: importStatusResult });
                }
            } catch (e) {
                console.error(e);
                if (canUpdateState) {
                    importDispatch({ type: ImportActionType.PollError, error: e });
                }
            }
        };

        loadStatus();
        const pollingIntervalHandle: number = window.setInterval(loadStatus, pollRate);

        return (): void => {
            // If the component unmounts, we don't want to update state for it.
            canUpdateState = false;
            window.clearInterval(pollingIntervalHandle);
        };
    }, [shouldPoll, listId, pollRate]);

    return [importState, importDispatch];
}

type ImportState = {
    file: File | null;
    isParsing: boolean;
    parsedFile: ParsedImportCsv | null;
    parseErrors: Array<string | { message: string }>;
    isUploading: boolean;
    uploadErrors: string[];
    importId: string | undefined | null;
    isPollingForStatus: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    pollingError: object | null; // should be a JSON response from the server or an Error object.
    importStatus: ImportStatusResult | null;
};

const initialImportState: ImportState = {
    file: null,
    isParsing: false,
    parsedFile: null,
    parseErrors: [],
    isUploading: false,
    uploadErrors: [],
    importId: null,
    isPollingForStatus: false,
    pollingError: null,
    importStatus: null
};

function importReducer(state: ImportState, action: any): ImportState {
    switch (action.type) {
        case ImportActionType.StartParse:
            return { ...initialImportState, file: action.file, isParsing: true };
        case ImportActionType.ParseSuccess:
            return { ...state, parsedFile: action.parsedFile, isParsing: false };
        case ImportActionType.ParseError:
            return { ...state, parseErrors: action.errors, isParsing: false, file: null };

        case ImportActionType.StartUpload:
            return { ...state, importId: null, uploadErrors: [], isUploading: true };
        case ImportActionType.UploadSuccess:
            return { ...state, importId: action.importId, isUploading: false };
        case ImportActionType.UploadError:
            return { ...state, uploadErrors: action.errors, isUploading: false };

        case ImportActionType.StartPoll:
            return { ...state, isPollingForStatus: true };
        case ImportActionType.PollSuccess:
            return { ...state, isPollingForStatus: false, importStatus: action.importStatus };
        case ImportActionType.PollError:
            return { ...state, isPollingForStatus: true, pollingError: action.error }; // We'll just try again, so keeping is-polling true.

        case ImportActionType.Reset:
            return initialImportState;
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

async function handleSelectFile(importDispatch: (action: any) => void, file: File) {
    importDispatch({ type: ImportActionType.StartParse, file: file });

    const importService: SubscriberImportService = bottle.container.SubscriberImportService;
    const parsedFile = await importService.parseFile(file);

    if (parsedFile.errors.length > 0) {
        importDispatch({ type: ImportActionType.ParseError, errors: parsedFile.errors });
        return;
    }

    importDispatch({ type: ImportActionType.ParseSuccess, parsedFile: parsedFile });
}

async function handleUpload(
    importDispatch: (action: any) => void,
    listId: number,
    parsedFile: ParsedImportCsv,
    replaceExisting: boolean,
    onUploaded?: (result: InitiateImportResult) => void
) {
    const importService: SubscriberImportService = bottle.container.SubscriberImportService;
    importDispatch({ type: ImportActionType.StartUpload });

    try {
        const result = await importService.initiateImport(parsedFile.data, listId, replaceExisting);

        onUploaded && onUploaded(result);

        importDispatch({ type: ImportActionType.UploadSuccess, importId: result.id });
    } catch (e: any) {
        importDispatch({ type: ImportActionType.UploadError, errors: [e.message || e.error] });
    }
}

interface UploadFormProps {
    importState: ImportState;
    onSelectFile: (filesUploaded: File[]) => void;
    onUpload: (parsedFile: ParsedImportCsv, replaceExisting: boolean) => Promise<void>;
}

function UploadForm({ importState, onSelectFile, onUpload }: UploadFormProps): ReactElement {
    const importService: SubscriberImportService = bottle.container.SubscriberImportService;
    const [replaceExisting, setReplaceExisting] = useState(false);
    const errors = importState.parseErrors.concat(importState.uploadErrors);
    const classes = useStyles();

    return (
        <>
            <FileUpload
                id="import-subscribers-file-field"
                fileTypes={["text/csv"]}
                onDrop={onSelectFile}
                filesUploaded={importState.file ? [importState.file] : []}
                label="Click or drag to add a file..."
            />

            {importState.isParsing && <Loader />}

            {errors && errors.length > 0 && (
                <>
                    {importState.parseErrors.length > 0 && (
                        <Typography color="error">
                            There {importState.parseErrors.length === 1 ? "was an error" : "were errors"} parsing this
                            file:
                        </Typography>
                    )}

                    {importState.uploadErrors.length > 0 && (
                        <Typography color="error">
                            There {importState.uploadErrors.length === 1 ? "was an error" : "were errors"} uploading
                            this file:
                        </Typography>
                    )}

                    <List id="upload-errors-list">
                        {errors.map((message, i) => {
                            return (
                                <ListItem key={i} disableGutters={true} dense={true}>
                                    <Typography>
                                        <>
                                            {typeof message !== "string" && message?.message
                                                ? message.message
                                                : message}
                                        </>
                                    </Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}

            <div style={{ margin: "1rem 0" }}>
                <Card
                    id="example-file-card"
                    content={
                        <>
                            <Typography variant="body1">
                                <button
                                    className={classes.exampleFileBtn}
                                    onClick={async () => await importService.getTemplateFile()}
                                >
                                    Example File
                                </button>
                            </Typography>

                            <ul>
                                <li>
                                    <Typography>Imported file must be a .csv file type</Typography>
                                </li>

                                <li>
                                    <Typography>The first row must include the label of the field</Typography>
                                </li>

                                <li>
                                    <Typography>
                                        File must include first name, last name, email address, and subscription type
                                    </Typography>
                                </li>
                            </ul>
                        </>
                    }
                />
            </div>

            <div>
                <FormControlLabel
                    id="fcl-replace-subscribers-fc"
                    key="replace-subscribers-fc"
                    value={replaceExisting ? "true" : "false"}
                    label="Replace current subscribers"
                    control={
                        <Checkbox
                            onChange={(e: any) => setReplaceExisting(e.target.checked)}
                            id="replace-subscribers-fc-checkbox"
                        />
                    }
                />
            </div>

            <ButtonGroup layout="right">
                <Button
                    disabled={!importState.parsedFile}
                    id="upload"
                    color="primary"
                    onClick={async () => importState.parsedFile && onUpload(importState.parsedFile, replaceExisting)}
                >
                    Upload
                </Button>
            </ButtonGroup>
        </>
    );
}

interface WaitingProps {
    importState: ImportState;
    totalToImport?: number | null; // When initiating an import, we have this number from parsing the import file.
}

function Waiting({ totalToImport, importState }: WaitingProps): ReactElement {
    let message = "Importing subscribers...";

    if (importState.isPollingForStatus) {
        message = "Fetching import status...";
    } else if (totalToImport) {
        message = `Importing subscriber${totalToImport > 1 ? "s" : ""}...`;
    }

    return (
        <>
            <Typography variant="body1" id="simple-modal-description">
                {message}
            </Typography>
            <Loader verticallyCenter={true} />
        </>
    );
}

interface ImportStatusMessageProps {
    orgId: string;
    listId: number;
    importStatus: ImportStatusResult;
    isPollingForStatus: boolean;
    onImportMore: () => void;
    onDoneImporting: () => void;
}

function ImportStatusMessage({
    orgId,
    listId,
    importStatus,
    isPollingForStatus,
    onImportMore,
    onDoneImporting
}: ImportStatusMessageProps): ReactElement {
    const pluralize = (word: string, count: number) => `${word}${count !== 1 ? "s" : ""}`;
    const prefixedPluralize = (word: string, count: number) => `${count} ${pluralize(word, count)}`;

    return (
        <>
            {(isPollingForStatus || importStatus.status === ImportStatus.Processing) && <Loader />}

            <div style={{ margin: "1rem" }} id="simple-modal-description">
                <Typography>{importStatus.totalCount} records.</Typography>

                <Typography>
                    {prefixedPluralize("subscriber", importStatus.successCount)}{" "}
                    {importStatus.successCount === 1 ? "was" : "were"} successfully imported.
                </Typography>

                {importStatus.removedCount > 0 && (
                    <Typography>
                        {prefixedPluralize("subscriber", importStatus.removedCount)}{" "}
                        {importStatus.successCount === 1 ? "was" : "were"} successfully removed.
                    </Typography>
                )}

                {importStatus.unvalidatedUserCount > 0 && (
                    <Typography>
                        {prefixedPluralize("subscriber", importStatus.unvalidatedUserCount)} will not receive
                        notifications until they have verified their account.
                    </Typography>
                )}

                <ReturnUrl orgId={orgId} listId={listId} importId={importStatus.id} />

                {importStatus.failedCount > 0 && (
                    <Card
                        id="failed-rows-card"
                        content={
                            <>
                                <Typography color="error">
                                    {prefixedPluralize("subscriber", importStatus.failedCount)} failed to import
                                    {importStatus.failedCount > Object.keys(importStatus.errors).length &&
                                        ` (showing first ${Object.keys(importStatus.errors).length})`}
                                    :
                                </Typography>

                                <List
                                    id="failed-rows-list"
                                    listItems={
                                        importStatus.errors &&
                                        Object.keys(importStatus.errors).map((rowNumber: string) => ({
                                            dense: true,
                                            itemText: {
                                                primary: `Row ${rowNumber}: ${importStatus.errors[Number(rowNumber)]}`
                                            }
                                        }))
                                    }
                                />
                            </>
                        }
                    />
                )}
            </div>

            <ButtonGroup layout="right">
                <Button id="importMore" onClick={onImportMore}>
                    Import more
                </Button>

                <Button id="close" color="primary" onClick={onDoneImporting}>
                    Done
                </Button>
            </ButtonGroup>
        </>
    );
}

function getSubscribersListUrl(orgId: string, listId: number, importId?: string): string {
    return `/${orgId}/admin/lists/${listId}/subscribers` + (importId ? `/${importId}` : "");
}

interface ReturnUrlProps {
    orgId: string;
    listId: number;
    importId: string;
}

function ReturnUrl({ orgId, listId, importId }: ReturnUrlProps): ReactElement | null {
    const url = `${window.location.origin}${getSubscribersListUrl(orgId, listId, importId)}`;

    return (
        <div style={{ paddingBottom: "1.2em", paddingTop: "1.2em" }}>
            <Typography>
                If you need to close this page, you may check your import status with the following url:{" "}
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
            </Typography>
        </div>
    );
}

import React from "react";

const Home: React.FC = () => {
    return (
        <div>
            <div>
                <h1>Notifications</h1>
            </div>
            <img src="http://www.civicplus.com/hubfs/CivicPlus%20Logo.png" alt="CivicPlus Logo" width="50%" />
        </div>
    );
};

export default Home;

import { Subscriptions, SubscriptionType } from "../entities/Subscriber";
import { SubscriptionList } from "../entities/SubscriptionList";

export class ManageSubscriptionsService {
    public isSubscribed(subscriptions: Subscriptions, type: SubscriptionType, list: SubscriptionList) {
        const subscription = subscriptions[list.id!];
        if (subscription == null || subscription === SubscriptionType.None) {
            return false;
        }
        if (type === SubscriptionType.EmailAndSms && subscription === SubscriptionType.Sms) {
            return true;
        }
        if (type === SubscriptionType.EmailAndSms && subscription === SubscriptionType.Email) {
            return true;
        }
        return subscription === type || subscription === SubscriptionType.EmailAndSms;
    }

    public calculateSubscriptionType(
        subscriptions: Subscriptions,
        current: SubscriptionType,
        list: SubscriptionList
    ): SubscriptionType {
        const isSubbed = this.isSubscribed(subscriptions, current, list);

        if (!isSubbed) {
            if (current === SubscriptionType.Email && this.isSubscribed(subscriptions, SubscriptionType.Sms, list)) {
                return SubscriptionType.EmailAndSms;
            }
            if (current === SubscriptionType.Sms && this.isSubscribed(subscriptions, SubscriptionType.Email, list)) {
                return SubscriptionType.EmailAndSms;
            }
            if (current === SubscriptionType.Email) {
                return SubscriptionType.Email;
            }
            if (current === SubscriptionType.Sms) {
                return SubscriptionType.Sms;
            }
        }

        if (isSubbed) {
            if (current === SubscriptionType.Email) {
                return this.isSubscribed(subscriptions, SubscriptionType.Sms, list)
                    ? SubscriptionType.Sms
                    : SubscriptionType.None;
            }
            if (current === SubscriptionType.Sms) {
                return this.isSubscribed(subscriptions, SubscriptionType.Email, list)
                    ? SubscriptionType.Email
                    : SubscriptionType.None;
            }
        }

        return current;
    }

    public convertTypeToMessage(type: SubscriptionType, list: SubscriptionList): string {
        switch (type) {
            case SubscriptionType.None:
                return `You are now unsubscribed from ${list.name}.`;
            case SubscriptionType.Sms:
                return `You are now subscribed to only SMS notifications for ${list.name}.`;
            case SubscriptionType.Email:
                return `You are now subscribed to only Email notifications for ${list.name}.`;
            case SubscriptionType.EmailAndSms:
                return `You are now subscribed to both Email and SMS notifications for ${list.name}.`;
            default:
                return "";
        }
    }
}

import React, { useEffect, useState, useMemo } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import StickyRightBar from "@civicplus/preamble-ui/lib/StickyRightBar";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { MessageHistory } from "../../../entities/Message";
import { bottle } from "../../../provider/Bottle";
import { ByUser } from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { MessageService } from "../../../services/MessageService";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { SubscriptionList } from "../../../entities/SubscriptionList";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { toDateTimeZone } from "@civicplus/preamble-ui/lib/Utilities/DateHelper";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import { useSnackbar } from "notistack";
import MessageHistoryTabs from "./MessageHistoryTabs";

interface MessageHistoryDetailProps {
    overrideBack?: () => any | null;
    isPendingMessage?: boolean;
}

const MessageHistoryDetail: React.FC<MessageHistoryDetailProps> = ({ overrideBack, isPendingMessage }) => {
    const location = useLocation();
    const { id, messageId: messageIdProp } = useParams<{ messageId: string; id: string }>();

    const messageService: MessageService = bottle.container.MessageService;
    const subscriptionListService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [list, setList] = useState<SubscriptionList | null>(null);
    const [selectedTab] = useState<number>(0);
    const [error, setError] = useState<string | "">("");
    const { enqueueSnackbar } = useSnackbar();

    const listId = useMemo(() => (id == null ? null : parseInt(id)), [id]);

    const messageId = useMemo(() => {
        if (messageIdProp) {
            return parseInt(messageIdProp);
        }
        const incomingMsg = (location.state as any).message;
        const msgId = incomingMsg && incomingMsg.id;

        return parseInt(msgId);
    }, [location.state, messageIdProp]);

    const [messageHistory, setMessageHistory] = useState<MessageHistory | null>(
        (location.state && (location.state as any).message) || null
    );

    const prevError = usePreviousErrors(error);

    useEffect(() => {
        let isCanceled = false;

        async function loadSubscriptionListById() {
            if (list === null && listId !== null) {
                const list = await subscriptionListService.getSubscriptionListById(listId.toString());
                if (!isCanceled) {
                    setList(list);
                }
            }
        }

        loadSubscriptionListById();

        return () => {
            isCanceled = true;
        };
    }, [list, listId, subscriptionListService]);

    useEffect(() => {
        let isCanceled = false;

        async function loadMessageDetails() {
            if (messageHistory && !isCanceled) {
                setIsLoading(false);
                return;
            }

            if (!messageId && !isCanceled) {
                setError("Message not found by specified id.");
                setIsLoading(false);
                return;
            }

            try {
                const message = await messageService.getMessageDetails(messageId as number, listId);

                if (!isCanceled) {
                    setMessageHistory(message);
                    setIsLoading(false);
                }
            } catch (e) {
                if (!isCanceled) {
                    const error = e as any;
                    setError(error.error);
                    setIsLoading(false);
                }
            }
        }
        loadMessageDetails();

        return () => {
            isCanceled = true;
        };
    }, [listId, messageHistory, messageId, messageService]);

    const goBack = () => {
        if (overrideBack) {
            return overrideBack();
        }
        window.history.back();
    };

    const sideDetails = () => {
        if (messageHistory != null && messageHistory.sender != null) {
            return (
                <div style={{ marginLeft: "3%" }}>
                    <ButtonGroup>
                        <Button onClick={goBack} id="messages-detail-cancel" fullWidth={true}>
                            Back
                        </Button>
                    </ButtonGroup>

                    <Typography variant="h6" gutterBottom={true}>
                        {messageHistory && messageHistory.isPendingMessage ? "Send at" : "Sent"}
                    </Typography>

                    <Typography variant="body1" paragraph={true} component="span">
                        <div>
                            <Tooltip
                                id="sent-tip"
                                title={toDateTimeZone(
                                    messageHistory.dateSent ?? "",
                                    settingsService.settings.defaultListSendTimeZoneLabel
                                )}
                            >
                                <span>
                                    {toDateTimeZone(
                                        messageHistory.dateSent ?? "",
                                        settingsService.settings.defaultListSendTimeZoneLabel
                                    )}
                                </span>
                            </Tooltip>
                        </div>

                        <span style={{ display: "inline-flex" }}>
                            by
                            <ByUser users={new Map()} entity={messageHistory.sender!} />
                        </span>
                    </Typography>
                </div>
            );
        }
    };

    useEffect(() => {
        if (error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [enqueueSnackbar, error, prevError]);

    return (
        <StickyRightBar id="message-history-right-bar" rightBar={sideDetails()}>
            {isLoading ? (
                <Loader verticallyCenter={true} />
            ) : (
                <MessageHistoryTabs
                    messageHistory={messageHistory}
                    selectedTab={selectedTab}
                    orgId={bottle.container.OrgService.orgId}
                    messageId={messageId! as number}
                    list={list}
                    isPendingMessage={isPendingMessage}
                />
            )}
        </StickyRightBar>
    );
};

export default MessageHistoryDetail;

import { ODataQuery } from "../entities/ODataQuery";
import { Paged } from "../entities/Paged";
import { SubscriptionList, SubscriptionListRequest } from "../entities/SubscriptionList";
import { ApiService } from "./ApiService";

export class SubscriptionListsService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getSubscriptionLists(
        page = 0,
        rowsPerPage = 25,
        adminView = false,
        sortColumn = "name",
        sortDirection = "asc",
        filter?: any
    ): Promise<Paged<SubscriptionList>> {
        const pathPrefix = adminView ? "subscriptionLists" : "subscriptions";

        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });
        return await this.apiService.getOdata(pathPrefix, odata);
    }

    public async getAllLists(): Promise<SubscriptionList[]> {
        const pathPrefix = "subscriptionLists/unpaged";
        return await this.apiService.get(pathPrefix);
    }

    public async getSubscriptionListById(id: string): Promise<SubscriptionList> {
        const subscriptionList: SubscriptionList = await this.apiService.get(`subscriptionLists/${id}`);
        return subscriptionList;
    }

    public async createSubscriptionList(subscriptionList: SubscriptionListRequest): Promise<SubscriptionList> {
        return await this.apiService.post("subscriptionLists", subscriptionList);
    }

    public async updateSubscriptionList(subscriptionList: SubscriptionListRequest): Promise<void> {
        return await this.apiService.put("subscriptionlists", subscriptionList);
    }

    public async deleteSubscriptionList(listId: number): Promise<void> {
        return await this.apiService.delete(`subscriptionlists/${listId}`);
    }
}

export class CacheProvider {
    private static cache: any = {};

    public set(key: string, value: any) {
        CacheProvider.cache[key] = value;
    }

    public clear(key: string) {
        delete CacheProvider.cache[key];
    }

    public clearAll() {
        CacheProvider.cache = {};
    }

    public get(key: string) {
        return CacheProvider.cache[key];
    }

    public async getOrDefault(key: string, func: () => Promise<any>) {
        const result = this.get(key);
        if (result) {
            return result;
        }
        const value = await func();
        this.set(key, value);
        return value;
    }
}

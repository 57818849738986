/* eslint-disable @typescript-eslint/no-explicit-any */

export type FormBuilderOptions = {
    id: string;
    fields: any[];
    data: any;
    onSave?: (e: any, result: any) => any | null;
    onInputChange?: (e: any, result: any) => any | null;
    onComplete?: (data: any) => any | null;
    onCancel?: (e?: any, result?: any, fromSave?: boolean) => any | null;
    buttonContainer?: HTMLElement | null;
    buttonGroupAlignment?: "left" | "center" | "right" | "justify" | "stacked" | undefined;
    showModal?: (e?: any, result?: any, fromSave?: boolean) => any | null;
    onConfirm?: (e?: any, result?: any, fromSave?: boolean) => any | null;
    onDirty?: () => any | null;
    dirty?: boolean;
    onDirtyState?: (value: boolean) => any | null;
    customToolbarButtons?: any;
};

export default class FormBuilder {
    // tranform data back from a form into an object with prop keys
    public static deconstructData(entity: any, data: any) {
        const newEntity: any = {};
        for (const key in entity) {
            if (data[key] !== undefined && data[key] !== null && data[key].toString() !== "") {
                newEntity[key] = data[key];
            } else {
                newEntity[key] = entity[key];
            }
        }
        return newEntity;
    }

    // transform an object into form builder data type
    public static constructData(entity: any) {
        const data: any = {};
        for (const key in entity) {
            data[key] = entity ? entity[key] : "";
        }
        return data;
    }

    // make form builder props, including buttons
    public static makeFormBuilderProps({
        id,
        fields,
        data,
        onSave,
        onDirty,
        onComplete,
        onCancel,
        onInputChange,
        buttonContainer,
        buttonGroupAlignment,
        dirty,
        customToolbarButtons
    }: FormBuilderOptions) {
        const saveActions: any = [];

        if (onSave) {
            saveActions.push({
                display: "Save",
                onClick: onSave,
                onComplete: onComplete || async function () {},
                buttonProps: {
                    color: "primary"
                }
            });
        }

        if (onCancel) {
            const cancel: any = {
                display: "Cancel",
                onClick: async (event: any, data: any) => {
                    onCancel(event, data);
                    return { skipToastNotification: true };
                },
                onComplete: onComplete || async function () {},
                bypassValidateBeforeOnClick: true
            };
            saveActions.push(cancel);
        }

        return {
            id,
            hideTagsField: true,
            getContentTypeAsync: () => Promise.resolve({ fields }),
            getContentAsync: () => Promise.resolve({ id: "nonce", data }),
            getUsersByIdsAsync: () => Promise.resolve({}),
            getClientsAsync: () => Promise.resolve({}),
            onDirty,
            saveActions,
            onCancel,
            onInputChange,
            onChange: (e: any) => {
                if (onInputChange) {
                    onInputChange(e, () => {});
                }
            },
            buttonContainer,
            buttonGroupAlignment,
            dirty,
            customToolbarButtons
        };
    }
}

export const verifyDuplicateFroala = (): void => {
    const froalaHeader = document.getElementById("subListFormBuilder-header");
    const froalaFooter = document.getElementById("subListFormBuilder-footer");

    const headerEditors = froalaHeader?.getElementsByClassName("fr-box fr-basic fr-top");
    if (headerEditors && headerEditors.length > 1) {
        headerEditors[0].remove();
    }

    const footerEditors = froalaFooter?.getElementsByClassName("fr-box fr-basic fr-top");
    if (footerEditors && footerEditors.length > 1) {
        footerEditors[0].remove();
    }
};

export default class FileHelper {
    static readText(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener("load", (event) => resolve(event.target && event.target.result));
            reader.addEventListener("error", (event) => reject(event.target && event.target.error));
            reader.addEventListener("abort", () => reject("File read aborted"));

            reader.readAsText(file);
        });
    }
}

export class ODataQuery {
    orderBy = "";
    orderByDirection = "";
    top = 25;
    skip = 0;
    search?: string;
    filter?: any;
    expand?: any;

    constructor({
        top,
        skip,
        orderBy,
        orderByDirection,
        search,
        filter,
        expand
    }: {
        top?: number;
        skip?: number;
        orderBy: string;
        orderByDirection: string;
        search?: string;
        filter?: any;
        expand?: any;
    }) {
        this.top = top || 25;
        this.skip = skip || 0;
        this.orderBy = orderBy || "";
        this.orderByDirection = orderByDirection || "";
        this.search = search;
        this.filter = filter;
        this.expand = expand;
    }
}

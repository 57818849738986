import { IHttpProvider } from "./base/HttpProvider";
import axios from "axios";

export class AxiosHttpProvider implements IHttpProvider {
    async get(url: string, config: any): Promise<any> {
        return await axios.get(url, config);
    }

    async post(url: string, body: any, config: any): Promise<any> {
        return axios.post(url, body, config);
    }

    async request(config: any): Promise<any> {
        return axios(config);
    }
}

import React, { useState } from "react";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { ContentTypeDetailsDto } from "@civicplus/hcms-api-sdk/dist/sdk";
import { bottle } from "../../provider/Bottle";
import { HcmsService } from "../../services/HcmsService";
import { NotificationDetails, PendingMessage } from "../../entities/PendingMessage";
import { NotificationOption } from "../../entities/NotificationOption";
import { Item, Scheduler, SchedulerProps } from "../Scheduler";

type ModalProps = {
    message: PendingMessage;
    onClose?: (reload: boolean) => void;
    contentType?: ContentTypeDetailsDto;
    open: boolean;
};

type ItemDto = {
    id: string;
    notificationDetails: NotificationDetails;
};

export const ModifyNotification: React.FC<ModalProps> = (props) => {
    const { message, contentType, open, onClose } = props;
    const [modalOpen, setModalOpen] = useState<boolean>(open);
    const [item, setItem] = useState<PendingMessage>(message);

    const hcmsService: HcmsService = bottle.container.HcmsService;

    const isAsset = (): boolean => {
        return !item.contentTypeId;
    };

    const onModalClose = (reload = false) => {
        setModalOpen(false);
        if (typeof reload === "object") {
            reload = false;
        }
        if (onClose) onClose(reload);
    };

    const confirm = async (item: Item) => {
        const notificationDetails = item.notificationDetails;
        if (notificationDetails.notificationOption === NotificationOption.OnPublishDate) {
            await updateNotificationDetails(notificationDetails);
        } else {
            await updateNotificationDetails(notificationDetails);
        }
        setItem((prev) => ({ ...prev, notificationDetails: notificationDetails }));
        onModalClose(true);
    };

    async function updateNotificationDetails(notificationDetails: NotificationDetails) {
        return await hcmsService.updateNotificationDetails(
            item.itemId,
            item.contentTypeName,
            notificationDetails,
            isAsset()
        );
    }

    const getSchedulerProps = () => {
        const pendingIem: ItemDto = item;

        const schedulerProps: SchedulerProps = {
            id: "modify-notification",
            status: "Published",
            onConfirm: confirm,
            onCancel: onModalClose,
            defaultEmailSubject: item.subject,
            defaultEmailIntroduction: item.notificationDetails.emailIntroduction,
            defaultTextMessage: item.notificationDetails.smsMessage,
            schema: contentType,
            item: pendingIem as Item
        };
        return schedulerProps;
    };

    return (
        <Dialog
            id="scheduler-modal"
            open={modalOpen}
            title="Notification"
            fullWidth={true}
            onClose={() => {
                onModalClose();
            }}
        >
            <Scheduler {...getSchedulerProps()} />
        </Dialog>
    );
};

import React, { useEffect } from "react";
import { bottle } from "../../provider/Bottle";
import { useLocation } from "react-router-dom";

interface SignInProps {
    fromRoute?: string;
}

const SignIn: React.FC<SignInProps> = ({ fromRoute }) => {
    const location = useLocation();

    useEffect(() => {
        async function init() {
            const { from } = (location.state as any) || {
                from: { pathname: "/" }
            };

            await bottle.container.UserManager.signIn(fromRoute || from.pathname);
        }
        init();
    }, [fromRoute, location.state]);

    return <div />;
};

export default SignIn;

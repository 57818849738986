import { formatPhoneNumber } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { SubscriptionType } from "../entities/Subscriber";

export class Formatters {
    static parsePhoneNumberField(field?: string) {
        if (field) {
            const parts = field.split("|");
            const phoneNumber = parts[0];
            return formatPhoneNumber(phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber);
        }
        return null;
    }

    static formatNumber(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static formatSubscriptionType(type: SubscriptionType) {
        switch (type) {
            case SubscriptionType.Email:
                return "Email";
            case SubscriptionType.Sms:
                return "SMS";
            case SubscriptionType.EmailAndSms:
                return "Both";
            case SubscriptionType.None:
                return "None";
            default:
                return "None";
        }
    }
}

import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";

export class StringHelper {
    static firstNonEmpty(...values: (string | undefined | null)[]) {
        for (const value of values) {
            if (!isNullOrWhiteSpace(value)) {
                return value!.trim();
            }
        }
        return null;
    }
}

export const emailValidation = (value: any, props: any, component: any) => {
    const result = {
        error: !isValidEmail(value),
        message: "This field must be a valid Email Address."
    };
    return result.error ? Promise.reject(result) : Promise.resolve(result);
};

// Validation is the JS equivalent of .Net EmailAddress Attribute.
export const isValidEmail = (value: string) => {
    const index = value.indexOf("@");
    return index > 0 && index !== value.length - 1 && index === value.lastIndexOf("@");
};

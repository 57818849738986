import React, { useEffect } from "react";
import { bottle } from "../../provider/Bottle";

const NoMatch: React.FC = () => {
    useEffect(() => {
        bottle.container.WindowRouter.assign(`/${(bottle.container.OrgService || {}).orgId}`);
    }, []);
    return null;
};

export default NoMatch;

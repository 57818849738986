/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Link from "@civicplus/preamble-ui/lib/Link";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { bottle } from "../../../provider/Bottle";
import { buildDateFilter, buildUserFilterFromOptionShape } from "../../../util/FilterHelper";
import { debounce } from "../../../util/Debounce";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { MessageService } from "../../../services/MessageService";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { searchUsers } from "../../../util/UserSearch";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { MessageHistory } from "../../../entities/Message";
import {
    ByUser,
    LastModified,
    makeDateRangeFilter,
    makeUsersFilter
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";

const ListMessageHistory: React.FC = () => {
    const orgService = bottle.container.OrgService;
    const messageService: MessageService = bottle.container.MessageService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const subscriptionListService: SubscriptionListsService = bottle.container.SubscriptionListsService;

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const listId = id != null ? parseInt(id) : null;
    const [selectedSubscriptionList, setSelectedSubscriptionList] = useState<string>();

    useEffect(() => {
        let isCancelled = false;
        async function getSubscriptionListForMessagesAsync() {
            if (listId) {
                const list = await subscriptionListService.getSubscriptionListById(listId!.toString());
                if (!isCancelled) {
                    setSelectedSubscriptionList(list.name);
                }
            }
        }

        getSubscriptionListForMessagesAsync();

        return () => {
            isCancelled = true;
        };
    }, [listId, subscriptionListService]);

    const timeZone = settingsService.settings.defaultListSendTimeZoneLabel;

    const columns = [
        {
            name: "id",
            options: { display: "excluded" }
        },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "dateSent",
            label: "Date Sent",
            options: {
                ...makeDateRangeFilter("Date Sent", timeZone, true),
                sort: true,
                filter: true
            }
        },
        {
            name: "sender",
            label: "Sender",
            options: {
                ...makeUsersFilter("Sender", debounce(searchUsers, 300), null, true),
                filter: true,
                sort: false
            }
        },
        {
            name: "listId",
            options: { display: "excluded" }
        }
    ];

    const onRowClick = (
        data: string[],
        meta: {
            dataIndex: number;
            rowIndex: number;
        },
        event: any
    ) => {
        const orgId = bottle.container.OrgService.orgId!;
        const messageId = data[0];

        navigate(`/${orgId}/admin/lists/${listId}/messages/history/${messageId}`);
    };

    const getRows = async (tableState: TableState): Promise<TableData> => {
        const messages = await messageService.getMessageHistory(
            tableState.page,
            tableState.rowsPerPage,
            listId!,
            (tableState.sortOrder.name || "id").replace("dateSent", "id"),
            tableState.sortOrder.direction || "desc",
            {
                ...buildDateFilter(tableState.filterList[2], "dateSent"),
                ...buildUserFilterFromOptionShape(tableState.filterList[3], "senderId")
            }
        );

        const data = messages.items!.map((m: MessageHistory) => {
            const dataRow: any[] = [
                m.id,
                m.subject,
                <LastModified
                    key={`lastmodified-${m.id}`}
                    date={m.dateSent!}
                    organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                />,
                <ByUser key={`byUser-${m.id}`} users={new Map()} entity={m.sender!} />,
                m.listIds == null ? listId : m.listIds[0]
            ];

            return dataRow;
        });

        return { count: messages.count, data };
    };

    return (
        <>
            <Titlebar
                id="titlebar"
                title="Message History"
                breadcrumbs={[
                    <Link key="list" component={RouterLink} to={`/${orgService.orgId}/admin/lists`}>
                        Subscription Lists
                    </Link>,
                    <Link component={RouterLink} key="current-list" to="../edit">
                        {selectedSubscriptionList || "Loading..."}
                    </Link>
                ]}
            />

            <AdvancedTableWrapper
                columns={columns}
                key={id}
                rows={getRows}
                emptyMessage="You have not sent any messages for this list!"
                scrollToTop={true}
                showFilter={true}
                download={false}
                initialSortDirection="desc"
                initialSortColumn="dateSent"
                initialSortColumnIndex={3}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                onRowClick={onRowClick}
            />
        </>
    );
};

export default ListMessageHistory;

import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

export const useStyles = makeStyles((theme) => ({
    navRail: {
        width: "120px !important",
        zIndex: 4,
        [theme.breakpoints.down("md")]: {
            width: "100% !important",
            height: "100px !important",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: "auto"
        },
        "&::-webkit-scrollbar": {
            width: 7,
            height: 10
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.grey[400],
            borderRadius: 25
        },
        "&::-webkit-scrollbar-track": {
            background: theme.palette.background.default
        }
    },
    drawer: {
        zIndex: "1200 !important"
    },
    drawerPaper: {
        padding: 0,
        left: 120,
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            left: "auto"
        }
    },
    drawerHeader: {
        display: "flex",
        position: "relative",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        alignItems: "center",
        borderBottom: "1px solid " + theme.palette.divider
    },
    drawerHeaderText: {
        flexGrow: 1,
        fontSize: theme.typography.body1.fontSize
    },
    page: {
        "& .prmbl-Inner-contentWrap": {
            left: 0,
            bottom: 100,
            [theme.breakpoints.up("md")]: {
                left: 120,
                bottom: 0
            }
        },
        "& .prmbl-pageInner": {
            [theme.breakpoints.down("md")]: {
                flexDirection: "row"
            }
        },
        "& .prmbl-topbar": {
            overflow: "auto"
        },
        "& .prmbl-topbar-right": {
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(0.5)
            }
        }
    },
    list: {
        overflow: "auto"
    }
}));

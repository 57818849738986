import { OrganizationSettings } from "../entities/OrganizationSettings";
import { ApiService } from "./ApiService";

export class OrganizationSettingsService {
    private readonly apiService: ApiService;
    private _settings: OrganizationSettings | null = null;

    public get settings(): OrganizationSettings {
        return this._settings!;
    }

    public set settings(value: OrganizationSettings) {
        this._settings = value;
    }

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getOrganizationSettings(): Promise<OrganizationSettings> {
        const settings: OrganizationSettings = await this.apiService.get("settings");

        const timeZoneParts = (settings.defaultListSendTime as any).split(":");
        const date = new Date();
        date.setHours(timeZoneParts[0]);
        date.setMinutes(timeZoneParts[1]);
        date.setSeconds(timeZoneParts[2]);
        settings.defaultListSendTime = date;

        this._settings = settings;
        return settings;
    }

    public async updateOrganizationSettings(organizationSettings: OrganizationSettings): Promise<void> {
        // timespan format: "0.00:00:00.0000"
        const padZero = (val: number) => (val < 10 ? "0" + val : val.toString());
        const d = organizationSettings.defaultListSendTime;
        const timeSpan = [d.getHours(), d.getMinutes(), d.getSeconds()].map(padZero).join(":");

        const payload: any = Object.assign({}, organizationSettings);
        payload.defaultListSendTime = `0.${timeSpan}.0000`;
        await this.apiService.put("settings", payload);
        this._settings = organizationSettings;
    }
}

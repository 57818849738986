import { ODataQuery } from "../entities/ODataQuery";
import { Paged } from "../entities/Paged";
import { Subscriber } from "../entities/Subscriber";
import { SubscriptionRequest } from "../entities/SubscriptionRequest";
import { ApiService } from "./ApiService";

export class SubscribersService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getSubscribers(listId: number | null, odata?: ODataQuery): Promise<Paged<Subscriber>> {
        return await this.apiService.getOdata(`subscribers/${listId ?? ""}`, odata);
    }

    public async getSubscriberById(subId: string): Promise<Subscriber> {
        const res = await this.apiService.get(`subscribers/${subId}`);
        return res;
    }

    public async getSubscribersExport(listId: number | null, listName: string, odata?: ODataQuery): Promise<any> {
        await this.apiService.getFile(
            `subscribers/${listId ?? "all"}/export`,
            `${listId === null ? "all" : listName}-subscribers.csv`,
            odata
        );
    }

    public async adminUnsubscribe(request: SubscriptionRequest): Promise<any> {
        return await this.apiService.put(`subscribers/${request.listId}/adminUnsubscribe`, request);
    }

    public async unsubscribe(request: SubscriptionRequest): Promise<any> {
        return await this.apiService.put(`subscribers/${request.listId}/unsubscribe`, request);
    }

    public async subscribe(request: SubscriptionRequest, options?: any): Promise<any> {
        return await this.apiService.post(`subscribers/${request.listId}`, request, options);
    }
}

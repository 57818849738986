import { MessageHistory } from "../entities/Message";
import { PendingMessage } from "../entities/PendingMessage";
import { ApiService } from "./ApiService";

export class PendingMessageService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async toMessage(listId: string, message: PendingMessage): Promise<MessageHistory> {
        return await this.apiService.post(`${listId}/pendingMessages/toMessage`, message);
    }
}

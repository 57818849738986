import { AssetDto, ContentDto, ContentTypeDetailsDto, FieldDto } from "@civicplus/hcms-api-sdk/dist/sdk";
import { NotificationOption } from "../entities/NotificationOption";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import DateTimePicker from "@civicplus/preamble-ui/lib/DateTimePicker";
import FormControl from "@civicplus/preamble-ui/lib/FormControl";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import RadioGroup from "@civicplus/preamble-ui/lib/RadioGroup";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import enhanceWithValidation, {
    minValueDateValidation,
    requiredValidation,
    validDateValidation
} from "@civicplus/preamble-ui/lib/Validations";
import React, { useEffect, useMemo, useState } from "react";
import { NotificationDetails, WithNumberTypeNotificationDetails } from "../entities/PendingMessage";

export function displayable(field: FieldDto): string {
    return field.properties.label || field.name;
}

const EnhancedDatePicker = enhanceWithValidation(DateTimePicker);

export interface SchedulerProps {
    id?: string;
    schema?: ContentTypeDetailsDto;
    item: Item;
    status?: string;
    onConfirm: (item: Item) => void;
    onCancel: () => void;
    defaultEmailSubject?: string;
    defaultEmailIntroduction?: string;
    defaultTextMessage?: string;
}

export enum HCMSScheduleOption {
    Now,
    Scheduled
}

export type Item = WithNumberTypeNotificationDetails<ContentDto> | WithNumberTypeNotificationDetails<AssetDto>;

export const Scheduler: React.FC<SchedulerProps> = (props) => {
    const [values, setValues] = useState<NotificationDetails>({
        ...props.item.notificationDetails,
        sendAt: props.item.scheduleJob?.dueTime
    });

    const [schedule, setSchedule] = useState(
        props.item.scheduleJob?.dueTime ? HCMSScheduleOption.Scheduled : HCMSScheduleOption.Now
    );

    const [notification, setNotification] = useState<NotificationOption>(
        props.item.notificationDetails?.notificationOption ?? NotificationOption.None
    );

    useEffect(() => {
        // We need to set notification option defaults if the user didn't type anything, and a default was provided.
        // We are specifically checking undefined, because we do not want to override anything the user has done
        const notificationDetails = {
            ...(props.item?.notificationDetails || {}),
            sendAt: props.item.scheduleJob?.dueTime
        } as NotificationDetails;

        if (notificationDetails.subject === undefined && props.defaultEmailSubject) {
            notificationDetails.subject = props.defaultEmailSubject;
        }
        if (notificationDetails.emailIntroduction === undefined && props.defaultEmailIntroduction) {
            notificationDetails.emailIntroduction = props.defaultEmailIntroduction;
        }
        if (notificationDetails.smsMessage === undefined && props.defaultTextMessage) {
            notificationDetails.smsMessage = props.defaultTextMessage;
        }

        setValues(notificationDetails);
    }, [props.item, props.defaultEmailSubject, props.defaultEmailIntroduction, props.defaultTextMessage]);

    const onConfirm = (): void => {
        const item = { ...props.item, notificationDetails: values } as Item;
        props.onConfirm(item);
    };

    const updateSchedule = (e: { target: { value: string } }): void => {
        const option = parseInt(e.target.value, 10);
        setSchedule(option);
        if (option === HCMSScheduleOption.Now) {
            setValues((prev) => ({ ...prev, sendAt: undefined }));
        }
    };

    const canSubmit = (): boolean => {
        if (schedule === HCMSScheduleOption.Scheduled && !values?.sendAt) {
            return false;
        }
        return true;
    };

    const scheduleOptions = useMemo(
        () => [
            {
                label: `Set to ${props.status} immediately.`,
                value: HCMSScheduleOption.Now,
                id: "nopt1"
            },
            {
                label: `Set to ${props.status} at a later date.`,
                value: HCMSScheduleOption.Scheduled,
                id: "nopt2"
            }
        ],
        [props.status]
    );

    const notificationOptions = useMemo(() => {
        if (props.status !== "Published") {
            return [];
        }
        const options: React.ComponentProps<typeof RadioGroup>["options"] = [
            { id: "op1", value: NotificationOption.None, label: "Do not send notification" },
            {
                id: "op2",
                value: NotificationOption.OnPublishDate,
                color: "default",
                label: "Send notification on publish date"
            }
        ];

        if (props.schema) {
            const date = props.schema.fields.find(
                (w) => w.properties.fieldType === "DateTime" || w.properties.fieldType === "DateTimeRange"
            );
            if (date) {
                options.push({
                    id: "op3",
                    value: NotificationOption.DaysInAdvance,
                    color: "default",
                    label: `Send based on ${displayable(date)} field`
                });
            }
        }
        return options;
    }, [props.status, props.schema]);

    const showExtraDetails = notification !== NotificationOption.None;
    return (
        <div id={props.id} className="prmbl-scheduler">
            <Grid container={true} spacing={2} sx={{ mb: 2 }}>
                <Grid xs={true}>
                    <FormControl margin="none">
                        <RadioGroup
                            id="schedule-options"
                            className="prmbl-scheduler-scheduleOptions"
                            label="Scheduling"
                            name="schedule-options"
                            options={scheduleOptions}
                            onChange={updateSchedule}
                            value={schedule}
                        />
                        {schedule === HCMSScheduleOption.Scheduled && (
                            <EnhancedDatePicker
                                className="prmbl-scheduler-changeStatusDate"
                                label="Publish Date"
                                format="MM/dd/yyyy h:mm a"
                                onChange={(date: Date): void => {
                                    setValues((prev) => ({ ...prev, sendAt: date }));
                                }}
                                value={values?.sendAt}
                                require={true}
                                validations={[requiredValidation, validDateValidation, minValueDateValidation]}
                                keyboard={true}
                                minValue={new Date()}
                                pickerType="dateTime"
                            />
                        )}
                    </FormControl>
                    {notificationOptions.length > 0 && (
                        <RadioGroup
                            id="notification-options"
                            className="prmbl-scheduler-notificationOptions"
                            label="Notify Your Users"
                            name="notifications-options"
                            options={notificationOptions}
                            onChange={(_e, value): void => {
                                const option = parseInt(value);
                                setValues((prev) => ({ ...prev, notificationOption: option }) as NotificationDetails);
                                setNotification(option as NotificationOption);
                            }}
                            value={notification}
                        />
                    )}
                </Grid>
                {showExtraDetails && (
                    <Grid className="prmbl-scheduler-notificationDetails" xs={6}>
                        <Typography variant="caption">Additional Details</Typography>
                        {notification === NotificationOption.DaysInAdvance && (
                            <TextInput
                                className="prmbl-scheduler-daysInAdvance"
                                type="number"
                                value={values?.daysInAdvance?.toString() ?? "0"}
                                label="Days in Advance"
                                onChange={(e): void => {
                                    const daysInAdvance = parseInt(e.target.value);
                                    setValues((prev) => ({
                                        ...prev,
                                        daysInAdvance
                                    }));
                                }}
                                fullWidth={true}
                            />
                        )}
                        <TextInput
                            className="prmbl-scheduler-emailSubject"
                            type="text"
                            value={values?.subject !== undefined ? values.subject : props.defaultEmailSubject}
                            label="Email Subject"
                            onChange={(e): void => {
                                setValues((prev) => ({ ...prev, subject: e.target.value }));
                            }}
                            fullWidth={true}
                        />
                        <TextInput
                            className="prmbl-scheduler-emailIntroduction"
                            type="text"
                            value={
                                values?.emailIntroduction !== undefined
                                    ? values.emailIntroduction
                                    : props.defaultEmailIntroduction
                            }
                            label="Email Introduction"
                            onChange={(e): void => {
                                setValues((prev) => ({ ...prev, emailIntroduction: e.target.value }));
                            }}
                            fullWidth={true}
                        />
                        <TextInput
                            className="prmbl-scheduler-textMessage"
                            type="text"
                            value={values?.smsMessage !== undefined ? values.smsMessage : props.defaultTextMessage}
                            label="Text Message (SMS)"
                            onChange={(e): void => {
                                setValues((prev) => ({ ...prev, smsMessage: e.target.value }));
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                )}
            </Grid>
            <ButtonGroup layout="right">
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button color="primary" onClick={onConfirm} disabled={!canSubmit()}>
                    Confirm
                </Button>
            </ButtonGroup>
        </div>
    );
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { AdvancedTableWrapper } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Link from "@civicplus/preamble-ui/lib/Link";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../../provider/Bottle";
import { buildCustomUserFilter, buildDateFilter } from "../../../util/FilterHelper";
import { Link as RouterLinkWithTypes, useNavigate, useParams } from "react-router-dom";
import { ModifyNotification } from "../../../components/Modals/ModifyNotification";
import { NotificationOption } from "../../../entities/NotificationOption";
import { PendingMessage } from "../../../entities/PendingMessage";
import { PendingMessageService } from "../../../services/PendingMessageService";
import { searchUsersAndClients } from "../../../util/UserSearch";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { hasAnyLink } from "../../../entities/hateos";
import {
    LastModified,
    ByUser,
    makeDateRangeFilter,
    makeUsersFilter
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import {
    OrganizationService,
    HcmsService,
    OrganizationSettingsService,
    SubscriptionListsService
} from "../../../services";
import { debounce } from "../../../util/Debounce";
import { ContentTypeDetailsDto } from "@civicplus/hcms-api-sdk/dist/sdk";

const RouterLink: any = RouterLinkWithTypes;

const PendingMessagesList: React.FC<any> = (props: any) => {
    const { isGlobalPending } = props;

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const orgService: OrganizationService = useMemo(() => bottle.container.OrgService, []);
    const hcmsService: HcmsService = useMemo(() => bottle.container.HcmsService, []);
    const settingsService: OrganizationSettingsService = useMemo(
        () => bottle.container.OrganizationSettingsService,
        []
    );
    const subscriptionListService: SubscriptionListsService = useMemo(
        () => bottle.container.SubscriptionListsService,
        []
    );

    const [showNotificationModal, setShowNotificationModal] = useState<boolean>();
    const [showCancelNotificationDialog, setShowCancelNotificationDialog] = useState<boolean>();
    const [selectedMessage, setSelectedMessage] = useState<PendingMessage | undefined>(undefined);
    const [selectedContentType, setSelectedContentType] = useState<ContentTypeDetailsDto>();
    const [selectedSubscriptionList, setSelectedSubscriptionList] = useState<any>(undefined);
    const [selectedSubscriptionListName, setSelectedSubscriptionListName] = useState<any>(undefined);

    const [thisMessages, setMessages] = useState<PendingMessage[] | null>(null);

    const refContainer: any = useRef<typeof AdvancedTableWrapper | null>(null);

    useEffect(() => {
        const getSubs = async () => {
            const list = await subscriptionListService.getSubscriptionListById(id);
            setSelectedSubscriptionList(list.id);
            setSelectedSubscriptionListName(list.name);
        };

        if (!isGlobalPending) {
            getSubs();
        }
    }, [id, isGlobalPending, subscriptionListService]);

    const getRows = async (state: TableState): Promise<TableData> => {
        let buildFilters = {
            ...buildDateFilter(state.filterList[2], "dateSent"),
            ...buildCustomUserFilter(state.filterList[4])
        };

        if (isGlobalPending) {
            buildFilters = {
                ...buildDateFilter(state.filterList[2], "dateSent"),
                ...buildCustomUserFilter(state.filterList[4]),
                ...buildDateFilter(state.filterList[7], "lastModified")
            };
        }

        const messages = await hcmsService.getPendingMessages(
            state.page,
            state.rowsPerPage,
            id,
            state.sortOrder.name || "dateSent",
            state.sortOrder.direction || "asc",
            buildFilters
        );

        const allMessages: PendingMessage[] = [];

        messages.items.map((m: any) => {
            if (m.listIds && m.listIds.length > 0 && isGlobalPending) {
                allMessages.push(m);
            } else if (!isGlobalPending) {
                allMessages.push(m);
            }
            return m;
        });

        setMessages(allMessages);

        const data = isGlobalPending
            ? allMessages.map((m: PendingMessage) => {
                  return [
                      m.id,
                      m.subject,
                      <LastModified
                          key={`${m.id}-created`}
                          date={m.scheduleJob?.dueTime || m.dateSent}
                          organizationTimeZone={settingsService.settings?.defaultListSendTimeZoneLabel}
                      />,
                      m.listIds == null ? 0 : m.listIds.length,
                      <ByUser key={`${m.id}-byUser`} users={new Map()} entity={m.sender} />,
                      m.listIds == null ? id : m.listIds[0],
                      <LastModified
                          key={`${m.id}-lastModified`}
                          date={m.lastModified!}
                          organizationTimeZone={settingsService.settings?.defaultListSendTimeZoneLabel}
                      />,
                      buildActionMenu(m)
                  ];
              })
            : messages.items.map((m: PendingMessage) => {
                  return [
                      m.id,
                      m.subject,
                      <LastModified
                          key={`${m.id}-created`}
                          date={m.scheduleJob?.dueTime || m.dateSent}
                          organizationTimeZone={settingsService.settings?.defaultListSendTimeZoneLabel}
                      />,
                      m.listIds == null ? 0 : m.listIds.length,
                      <ByUser key={`${m.id}-byUser`} users={new Map()} entity={m.sender} />,
                      m.listIds == null ? id : m.listIds[0],
                      buildActionMenu(m)
                  ];
              });

        return { count: messages.total, data };
    };

    const buildActionMenu = useCallback(
        (message: PendingMessage) => {
            if (!hasAnyLink(message.links, "update")) {
                return null;
            }

            return (
                <Menu
                    title="Actions"
                    stopPropagation={true}
                    id={`${message.id}-action-menu`}
                    type="action"
                    items={[
                        {
                            children: "Modify",
                            onClick: async () => {
                                const contentType = await hcmsService.getContentType(message.contentTypeId);
                                setSelectedMessage(message);
                                setSelectedContentType(contentType);
                                setShowNotificationModal(true);
                            }
                        },
                        {
                            children: "Cancel",
                            onClick: async () => {
                                setShowCancelNotificationDialog(true);
                                setSelectedMessage(message);
                            }
                        }
                    ]}
                />
            );
        },
        [hcmsService]
    );

    const closeNotificationModal = async (reload = false) => {
        setShowNotificationModal(false);

        if (reload && refContainer) {
            const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
            await tableRef.loadRows();
        }
    };

    const cancelNotification = async (message: any) => {
        setShowCancelNotificationDialog(false);

        if (message.notificationDetails && refContainer) {
            const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
            tableRef.setLoading();

            message.notificationDetails.notificationOption = NotificationOption.None;

            await hcmsService.updateNotificationDetails(
                message.itemId,
                message.contentTypeName,
                message.notificationDetails,
                !message.contentTypeId
            );

            await tableRef.loadRows();
        }
    };

    const onRowClick = async (
        data: string[],
        meta: {
            dataIndex: number;
            rowIndex: number;
        },
        event: any
    ) => {
        if (refContainer) {
            const tableRef = refContainer && (refContainer.current as typeof AdvancedTableWrapper);
            tableRef.setLoading();
        }

        const service: PendingMessageService = bottle.container.PendingMessageService;
        const orgId = bottle.container.OrgService.orgId;
        const msg = thisMessages?.find((m: any) => m.id === data[0]);

        if (isGlobalPending) {
            const converted = await service.toMessage(data[5], msg!);
            const messageId = data[0];
            converted.isPendingMessage = true;

            navigate(`/${orgId}/admin/messages/pending/${data[5]}/${messageId}`, { state: { message: converted } });
        } else {
            const converted = await service.toMessage(selectedSubscriptionList!.toString(), msg!);
            converted.isPendingMessage = true;

            navigate(`/${orgId}/admin/lists/${selectedSubscriptionList}/messages/pending/view`, {
                state: { message: converted }
            });
        }
    };

    const timeZone = useMemo(() => settingsService.settings?.defaultListSendTimeZoneLabel, []);
    const displayCount = id == null ? "true" : "excluded";

    const columns = [
        { name: "id", label: "id", options: { filter: false, display: "excluded" } },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "dateSent",
            label: "Scheduled Date",
            options: {
                ...makeDateRangeFilter("Scheduled Date", timeZone, true),
                sort: true,
                filter: true
            }
        },
        {
            name: "listCount",
            label: "Lists",
            options: {
                filter: false,
                sort: false,
                display: displayCount
            }
        },
        {
            name: "sender",
            label: "Sender",
            options: {
                ...makeUsersFilter("Sender", debounce(searchUsersAndClients, 300), undefined, true),
                sort: false,
                filter: true
            }
        },
        {
            name: "listId",
            options: { display: "excluded" }
        }
    ];

    if (isGlobalPending) {
        columns.push(
            {
                name: "lastModified",
                label: "Last edited",
                options: {
                    ...makeDateRangeFilter("Last Edited Date", timeZone, true),
                    sort: true,
                    filter: true
                }
            },
            AdvancedTableWrapper.defaultActionMenuColumn()
        );
    } else {
        columns.push(AdvancedTableWrapper.defaultActionMenuColumn());
    }

    return (
        <>
            <Titlebar
                id="titlebar"
                title="Pending Messages"
                breadcrumbs={[
                    // @ts-ignore
                    <Link component={RouterLink} key="list" to={`/${orgService.orgId}/admin/lists`}>
                        Subscription Lists
                    </Link>,
                    selectedSubscriptionListName ? (
                        <Link
                            component={RouterLink}
                            key="current-list"
                            // @ts-ignore
                            to={`/${orgService.orgId}/admin/lists/${id}/edit`}
                        >
                            {selectedSubscriptionListName}
                        </Link>
                    ) : isGlobalPending ? (
                        <p>Loading...</p>
                    ) : (
                        <></>
                    )
                ]}
            />

            <AdvancedTableWrapper
                key={id?.toString()}
                columns={columns}
                rows={getRows}
                emptyMessage="You currently have no pending messages for this list."
                ref={refContainer}
                initialSortDirection="asc"
                initialSortColumn="dateSent"
                showFilter={true}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                onRowClick={onRowClick}
                scrollToTop={true}
                initialSortColumnIndex={1}
                showSearch={false}
                download={false}
                closeSnackbar={() => {}}
                enqueueSnackbar={() => "message"}
                enablePreload={false}
                serverSide={true}
            />

            {showNotificationModal && selectedMessage && (
                <ModifyNotification
                    message={selectedMessage}
                    contentType={selectedContentType}
                    onClose={async (reload: boolean) => await closeNotificationModal(reload)}
                    open={true}
                />
            )}

            {showCancelNotificationDialog && (
                <Dialog
                    title="Cancel Notfication"
                    id="cancel-notification"
                    maxWidth="md"
                    open={showCancelNotificationDialog}
                    onClose={() => {
                        setShowCancelNotificationDialog(false);
                    }}
                    actions={[
                        <Button
                            color="primary"
                            onClick={async () => await cancelNotification(selectedMessage)}
                            key="ok"
                        >
                            Ok
                        </Button>
                    ]}
                >
                    <Typography>Are you sure you want to cancel this notification?</Typography>
                </Dialog>
            )}
        </>
    );
};

export { PendingMessagesList };

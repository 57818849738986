import { EmailTemplate, EmailTemplateType } from "../entities/Template";
import { ApiService } from "./ApiService";

export class TemplateService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    /**
     * Gets the organization level header if list id is not passed, otherwise gets the header for the specific list.
     * @param listId
     */
    async getHeader(listId: number | null = null) {
        return await this.get(EmailTemplateType.Header, listId);
    }

    /**
     * Gets the organization level footer if list id is not passed, otherwise gets the footer for the specific list.
     * @param listId
     */
    async getFooter(listId: number | null = null): Promise<string> {
        return await this.get(EmailTemplateType.Footer, listId);
    }

    /**
     * Gets the organization level welcome message if list id is not passed, otherwise gets the welcome message for the specific list.
     * @param listId
     */
    async getWelcomeMessage(listId: number | null = null): Promise<string> {
        return await this.get(EmailTemplateType.WelcomeMessage, listId);
    }

    private async get(type: EmailTemplateType, listId: number | null = null): Promise<string> {
        return await this.apiService.get(`templates?type=${type}${listId ? "&listId=" + listId : ""}`);
    }

    /**
     * Saves the template at the organization level if list id is not passed, otherwise sets the template for the specific list only.
     * @param template -- array of template: string, type: EmailTemplateType,
     * @param listId
     */
    async save(template: EmailTemplate[], listId: number | null = null) {
        const data = new Map<EmailTemplateType, string>();
        template.forEach((element) => {
            data.set(element.type, element.template);
        });

        await this.apiService.post(`templates${listId ? "/" + listId : ""}?`, JSON.stringify(template), {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
}

import { bottle } from "../../../provider/Bottle";
import { ApiService } from "../../../services/ApiService";
import FormBuilderHelper from "../../../util/FormBuilder";
import { setAltText } from "../../../util/FroalaHelper";

export class TemplateFormBuilder {
    private readonly props: TemplateFormBuilderProps;
    private readonly fields: any[];
    private readonly data: any;

    constructor(props: TemplateFormBuilderProps) {
        this.props = props;
        this.fields = [];
        if (props.includeWelcomeMessage) {
            this.fields.push(
                this.makeField(
                    "welcomeMessage",
                    "Welcome Message",
                    "RichText",
                    "Welcome Message",
                    props.defaultState.welcomeMessage
                )
            );
        }
        this.fields.push(this.makeField("header", "Header", "RichText", "Header", props.defaultState.header));
        this.fields.push(this.makeField("footer", "Footer", "RichText", "Footer", props.defaultState.footer));
        this.data = FormBuilderHelper.constructData(props.defaultState);
    }

    private makeField = (
        name: string,
        label: string,
        editor: string,
        placeholder: string,
        value?: string,
        helperText?: string
    ) => {
        return {
            name,
            partioning: "invariant",
            value,
            properties: {
                id: "defaultTemplate-" + name.charAt(0).toUpperCase() + name.slice(1),
                disabled: false,
                isRequired: false,
                isListField: false,
                fieldType: "String",
                label,
                placeholder,
                editor,
                hints: helperText,
                maxLength: 1000
            }
        } as any;
    };

    public fieldsAndDataOnly = () => {
        return {
            fields: this.fields,
            data: this.data
        };
    };

    public build = async () => {
        return {
            ...FormBuilderHelper.makeFormBuilderProps({
                ...this.builderProps()
            }),
            froalaEvents: this.froalaEvents(),
            fieldConfiguration: await this.defaultFroalaEditorConfig(),
            customToolbarButtons: this.customToolbarButtons(),
            fields: this.fields
        };
    };

    public defaultFroalaEditorConfig = async (): Promise<{ customFroalaEditorConfig: Record<string, unknown> }> => {
        const api: ApiService = bottle.container.ApiService;
        const headers = await api.defaultHeaders();
        return {
            customFroalaEditorConfig: {
                imageDefaultWidth: 0,
                imageUploadURL: `${api.baseOrgRoute}/files`,
                imageUploadMethod: "POST",
                imageAllowedTypes: ["gif", "jpeg", "jpg", "png", "blob"],
                requestHeaders: headers
            }
        };
    };

    private builderProps = () => {
        return {
            id: "orgTemplateFormBuilder",
            fields: this.fields,
            data: this.data,
            onSave: this.props.onSave || function () {},
            onComplete: this.props.onComplete || function () {},
            onInputChange: this.props.onChange,
            onDirtyState: this.props.onDirtyState,
            buttonContainer: this.props.buttonContainer,
            customToolbarButtons: this.customToolbarButtons()
        };
    };

    public froalaEvents = () => {
        return [
            {
                eventName: "froalaEditor.image.inserted",
                eventCallback: async (e: any, editor: any, img: any, response: any) => {
                    editor.image.hideProgressBar();
                    editor.popups.hideAll();
                    setAltText(e, editor, img);
                }
            },
            {
                eventName: "froalaEditor.image.replaced",
                eventCallback: async (e: any, editor: any, error: any, response: any) => {
                    editor.image.hideProgressBar();
                    editor.popups.hideAll();
                }
            }
        ];
    };

    public customToolbarButtons = () => {
        return [
            {
                name: "insertImage",
                icon: "image",
                title: "Insert Image",
                callback: (editor: any) => this.props.imageButtonClicked(editor, "upload"),
                replaceCallback: (editor: any) => this.props.imageButtonClicked(editor, "replace"),
                isEditImage: false
            }
        ];
    };
}

export type TemplateFormBuilderProps = {
    defaultState: { header: string; footer: string; welcomeMessage?: string };
    onSave?: (event: any, data: any) => any | null;
    onComplete?: () => any | null;
    imageButtonClicked: (editor: any, identifier: any) => any;
    includeWelcomeMessage?: boolean;
    onChange?: (event: any, data: any) => any | null;
    buttonContainer?: HTMLElement | null;
    onDirtyState: (value: boolean) => any | null;
    dirty?: boolean;
};

import { Organization, OrganizationDto } from "../entities/Organization";
import { Subscriber } from "../entities/Subscriber";
import { ProductType } from "../entities/ProductType";
import { ApiService } from "./ApiService";
import { BrandingSettings } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { CacheProvider } from "../provider/cache/cacheProvider";
import { Paged } from "../entities/Paged";

export class OrganizationService {
    private readonly apiService: ApiService;
    private readonly cache: CacheProvider;

    constructor(apiService: ApiService, cache: CacheProvider) {
        this.apiService = apiService;
        this.cache = cache;
    }

    public async getOrganization(): Promise<OrganizationDto> {
        return await this.cache.getOrDefault(`org_full`, async () => {
            return await this.apiService.get("organization");
        });
    }

    public async getBranding(): Promise<BrandingSettings> {
        return await this.cache.getOrDefault(`org_citizen_branding`, async () => {
            return await this.apiService.get("citizen/branding");
        });
    }

    public async searchOrgUsers(search: string): Promise<Subscriber[]> {
        return await this.apiService.get(`organization/userSearch?search=${search}`);
    }

    public async searchOrgs(search: string): Promise<Paged<Organization>> {
        return await this.apiService.getNoOrg(`searchOrgs/${search}`);
    }

    public async getAssociatedOrgs(search: string): Promise<Organization[]> {
        return await this.apiService.getNoOrg(`associatedOrganizations/${search}`);
    }

    public async getProductTypes(): Promise<ProductType> {
        return await this.cache.getOrDefault("product_types", async () => {
            return await this.apiService.get("productTypes");
        });
    }
}

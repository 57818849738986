import React from "react";
import List from "@civicplus/preamble-ui/lib/List";
import ListSubheader from "@civicplus/preamble-ui/lib/ListSubheader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

interface AdministrationDrawerMenuProps {
    orgId: string;
    onClick: (show: boolean) => void;
    isSuperUser: boolean;
    isOrgOwner?: boolean;
    hasAccess?: boolean;
}

const AdministrationDrawerMenu: React.FC<AdministrationDrawerMenuProps> = (props) => {
    const { orgId, onClick, isSuperUser, isOrgOwner, hasAccess } = props;
    const classes = useStyles();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const listItems = [];

    if (isSuperUser || isOrgOwner) {
        listItems.push(
            {
                button: true,
                isSideNav: true,
                selected: pathname.toLowerCase() === `/${orgId}/admin/settings`,
                itemText: {
                    primary: "Settings"
                },
                onClick: () => {
                    onClick(false);
                    navigate(`/${orgId}/admin/settings`);
                }
            },
            {
                button: true,
                isSideNav: true,
                selected: pathname.toLowerCase() === `/${orgId}/admin/permissions`,
                itemText: {
                    primary: "Permissions"
                },
                onClick: () => {
                    onClick(false);
                    navigate(`/${orgId}/admin/permissions`);
                }
            },
            {
                button: true,
                isSideNav: true,
                selected: pathname.toLowerCase() === `/${orgId}/admin/templates`,
                itemText: {
                    primary: "Templates"
                },
                onClick: () => {
                    onClick(false);
                    navigate(`/${orgId}/admin/templates`);
                }
            }
        );
    }

    if (isSuperUser || isOrgOwner || hasAccess) {
        listItems.push({
            button: true,
            isSideNav: true,
            selected: pathname.toLowerCase() === `/${orgId}/admin/history`,
            itemText: {
                primary: "History"
            },
            onClick: () => {
                onClick(false);
                navigate(`/${orgId}/admin/history`);
            }
        });
    }

    if (isSuperUser) {
        listItems.push({
            button: true,
            isSideNav: true,
            selected: pathname.toLowerCase() === "/admin/user/activity",
            itemText: {
                primary: "User Activity"
            },
            onClick: () => {
                onClick(false);
                navigate("/admin/user/activity");
            }
        });
    }

    return (
        <>
            <ListSubheader isSideNav="header" className={classes.drawerHeader}>
                <Typography variant="button" className={classes.drawerHeaderText}>
                    Administration
                </Typography>
            </ListSubheader>

            <List
                key="org-administration-list"
                id="org-administration-list"
                sideNav={true}
                listItems={listItems}
                className={classes.list}
            />
        </>
    );
};

export default AdministrationDrawerMenu;

import { ApiService } from "./ApiService";

export class AuthService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getConfig(): Promise<any> {
        return await this.apiService.getIdentity("account/config");
    }
}

import React from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { bottle } from "../../../provider/Bottle";
import { buildDateFilter, buildUserFilterFromOptionShape } from "../../../util/FilterHelper";
import { useNavigate } from "react-router-dom";
import { MessageService } from "../../../services/MessageService";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { searchUsers } from "../../../util/UserSearch";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import {
    ByUser,
    LastModified,
    makeDateRangeFilter,
    makeUsersFilter
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { debounce } from "../../../util/Debounce";

const GlobalMessageHistory: React.FC = () => {
    const messageService: MessageService = bottle.container.MessageService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const navigate = useNavigate();

    const timeZone = settingsService.settings.defaultListSendTimeZoneLabel;
    const columns = [
        {
            name: "id",
            options: { display: "excluded" }
        },
        {
            name: "subject",
            label: "Subject",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "dateSent",
            label: "Date Sent",
            options: {
                ...makeDateRangeFilter("Date Sent", timeZone, true),
                sort: true,
                filter: true
            }
        },
        {
            name: "sender",
            label: "Sender",
            options: {
                ...makeUsersFilter("Sender", debounce(searchUsers, 300), null, true),
                filter: true,
                sort: false
            }
        },
        {
            name: "listId",
            options: { display: "excluded" }
        },
        {
            name: "emailCount",
            label: "Email Sent",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "smsCount",
            label: "Sms Sent",
            options: {
                filter: false,
                sort: false
            }
        }
    ];

    const onRowClick = (
        data: string[],
        meta: {
            dataIndex: number;
            rowIndex: number;
        },
        event: any
    ) => {
        const orgId = bottle.container.OrgService.orgId!;
        const messageId = data[0];
        navigate(`/${orgId}/admin/messages/history/${messageId}`);
    };

    const getRows = async (tableState: TableState): Promise<TableData> => {
        const messages = await messageService.getMessageHistory(
            tableState.page,
            tableState.rowsPerPage,
            null,
            (tableState.sortOrder.name || "id").replace("dateSent", "id"),
            tableState.sortOrder.direction || "desc",
            {
                ...buildDateFilter(tableState.filterList[2], "dateSent"),
                ...buildUserFilterFromOptionShape(tableState.filterList[3], "senderId")
            }
        );

        const data = messages.items.map((m) => {
            const dataRow: any[] = [
                m.id,
                m.subject,
                <LastModified
                    key={`lastmodified-${m.id}`}
                    date={m.dateSent!}
                    organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                />,
                <ByUser users={new Map()} entity={m.sender!} />,
                m.listIds == null ? 0 : m.listIds[0],
                m.emailCount,
                m.smsCount
            ];

            return dataRow;
        });

        return { count: messages.count, data };
    };

    return (
        <>
            <Titlebar id="titlebar" title="Message History" />

            <AdvancedTableWrapper
                columns={columns}
                rows={getRows}
                emptyMessage="You have not sent any messages for this organization!"
                scrollToTop={true}
                showFilter={true}
                download={false}
                initialSortDirection="desc"
                initialSortColumn="dateSent"
                initialSortColumnIndex={3}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                onRowClick={onRowClick}
            />
        </>
    );
};

export default GlobalMessageHistory;

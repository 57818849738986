import React, { useState, useEffect } from "react";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete/Autocomplete";
import sortBy from "lodash/sortBy";
import { ProductType, ProductTypeLabels } from "../../../entities/ProductType";

export type ProductTypeDto = {
    friendlyName: string;
    name: string;
    value: ProductType | number;
};

export interface ProductTypeSelectorProps {
    className?: string;
    disabled?: boolean;
    helperText?: string;
    includedApps: number[];
    isInDialog?: boolean;
    label?: string;
    onChange: (productType: ProductTypeDto[]) => void;
    placeholder?: string;
    required?: boolean;
    value: (ProductType | number)[];
}

export const ProductTypeSelector: React.FC<ProductTypeSelectorProps> = (props: ProductTypeSelectorProps) => {
    const [productTypes, setProductTypes] = useState<ProductTypeDto[]>([]);
    const [value, setValue] = useState<ProductTypeDto[]>([]);

    useEffect((): void => {
        function load() {
            const result: ProductTypeDto[] = [];

            for (let i = 0; i < props.includedApps.length; i++) {
                result.push({
                    friendlyName: ProductTypeLabels[props.includedApps[i]],
                    name: ProductType[props.includedApps[i]],
                    value: props.includedApps[i]
                });
            }

            setProductTypes(sortBy(result, [(c): string => c.friendlyName.toLowerCase()]));

            const selectedProductTypes = props.value
                ? props.value
                      .map((item) => {
                          return result.find((x) => x.value === item);
                      })
                      .filter((w) => !!w)
                      .filter((val, index, self) => self.indexOf(val) === index)
                : [];

            const selectedValues: number[] = [];

            selectedProductTypes.forEach((v) => {
                if (typeof v?.value === "number") selectedValues.push(v.value);
            });

            setValue(selectedProductTypes as ProductTypeDto[]);
        }
        load();
    }, [props.value, props.includedApps]);

    return (
        <Autocomplete
            id="list-product-types"
            isInDialog={props.isInDialog ?? false}
            disabled={props.disabled}
            required={props.required}
            helperText={props.helperText}
            label={props.label}
            multiSelect={true}
            placeholder={props.placeholder ?? ""}
            value={value.map((s) => ({ label: s.friendlyName, value: s.value }))}
            options={productTypes.map((s) => ({ label: s.friendlyName, value: s.value }))}
            onChange={(selected: OptionShape[] | undefined): void => {
                selected = selected ?? [];

                const selectedProductTypes = selected
                    .map((item) => productTypes.find((w) => w.friendlyName === item.label))
                    .filter((w) => w != null);

                setValue(selectedProductTypes as ProductTypeDto[]);
                props.onChange?.(selectedProductTypes as ProductTypeDto[]);
            }}
        />
    );
};

ProductTypeSelector.defaultProps = {
    label: "Products",
    value: []
};

import React, { useCallback, useEffect, useState, useMemo } from "react";
import AdministrationDrawerMenu from "./AdministrationDrawerMenu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import NavigationRail from "@civicplus/preamble-ui/lib/NavigationRail";
import NotificationsOrganizationService from "../../provider/notificationsOrganizationService";
import Page from "@civicplus/preamble-ui/lib/Page";
import Pendo from "../../components/Pendo";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import SvgIcon from "@mui/material/SvgIcon";
import ViewListIcon from "@mui/icons-material/ViewList";
import Zendesk from "@civicplus/preamble-ui/lib/ThirdParty/ZenDesk/Zendesk";
import { bottle } from "../../provider/Bottle";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { ConfigOptions } from "../../entities/ConfigOptions";
import { getCppOrganizationFromOrganization } from "../admin/shared/functions";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { mdiCalendarClock, mdiEmailArrowRightOutline } from "@mdi/js";
import { Organization } from "../../entities/Organization";
import { OrganizationSettings } from "../../entities/OrganizationSettings";
import { ReactComponent as MySubscriptionLogo } from "../../app_registration-24px.svg";
import { User } from "oidc-client-ts";
import { UserManager } from "../../services/UserManager";
import { useStyles } from "./styles";

interface MenuItem {
    children: string;
    onClick: () => Window | void | null;
}

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

interface PageWrapperProps {
    isCitizenView: boolean;
    currentOrgSettings: OrganizationSettings;
    width?: any;
    children: any;
}

const PageWrapperInternal: React.FC<PageWrapperProps> = ({ isCitizenView, currentOrgSettings, width, children }) => {
    const orgService = bottle.container.OrgService;
    const apiService = bottle.container.ApiService;
    const userManager = bottle.container.UserManager as UserManager;
    const configOptions = bottle.container.ConfigOptions as ConfigOptions;

    const classes = useStyles();
    const [organization, setOrganization] = useState<Organization | null>();
    const [orgId, setOrgId] = useState<string>("");
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [signoutUrl, setSignoutUrl] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [authUser, setAuthUser] = useState<User>();
    const [activeBtn, setActiveBtn] = useState<number>();
    const [showDrawer, setShowDrawer] = useState(false);
    const [orgNavButtons, setOrgNavButtons] = useState([] as React.ComponentProps<typeof NavigationRail>["buttons"]);
    const location = useLocation();

    useEffect(() => {
        const getUser = async () => {
            const user = await userManager.getUserAsync();
            setAuthUser(user);
        };

        getUser();
    }, [userManager]);

    const setupZenDesk = useCallback(async () => {
        if (!isCitizenView) {
            await Zendesk.initialize({
                closeButtonColor: undefined,
                departmentSelected: "CP Notify",
                departments: ["CP Notify"],
                key: "c9a79f7a-2e63-46e3-b5e0-82f7cba19902"
            });

            if (authUser && !authUser.expired) {
                Zendesk.identify({
                    firstName: authUser.profile.given_name,
                    lastName: authUser.profile.family_name,
                    email: authUser.profile.email
                });
            }
            const key = "show_zendesk_onload";
            Zendesk.onHide(() => {
                localStorage.removeItem(key);
            });
            Zendesk.onShow(() => {
                localStorage.setItem(key, "true");
            });
            if (localStorage.getItem(key)) {
                Zendesk.show();
            }
        }
    }, [authUser, isCitizenView]);

    const buildNavigation = useCallback(() => {
        let orgAppNavBtns: React.ComponentProps<typeof NavigationRail>["buttons"] = [];

        if (isSuperUser || organization?.isOrgOwner || organization?.hasAccess) {
            orgAppNavBtns.push({
                label: "Dashboard",
                icon: <DashboardIcon />,
                buttonProps: {
                    id: "Dashboard",
                    to: `/${orgId}/admin`,
                    component: RouterLink
                }
            });

            orgAppNavBtns.push({
                label: "Pending Messages",
                icon: (
                    <SvgIcon color="inherit">
                        <path d={mdiCalendarClock} />
                    </SvgIcon>
                ),
                buttonProps: {
                    id: "PendingMessages",
                    to: `/${orgId}/admin/messages/pending`,
                    component: RouterLink
                }
            });

            orgAppNavBtns.push({
                label: "Sent Messages",
                icon: (
                    <SvgIcon color="inherit">
                        <path d={mdiEmailArrowRightOutline} />
                    </SvgIcon>
                ),
                buttonProps: {
                    id: "SentMessages",
                    to: `/${orgId}/admin/messages/history`,
                    component: RouterLink
                }
            });

            orgAppNavBtns.push({
                label: "Subscribers",
                icon: <PeopleIcon />,
                buttonProps: {
                    id: "Subscribers",
                    to: `/${orgId}/admin/subscribers`,
                    component: RouterLink
                }
            });

            orgAppNavBtns.push({
                label: "Subscription Lists",
                icon: <ViewListIcon />,
                buttonProps: {
                    id: "SubscriptionLists",
                    to: `/${orgId}/admin/lists`,
                    component: RouterLink
                }
            });
        }

        orgAppNavBtns.push({
            label: "My Subscriptions",
            icon: (
                <SvgIcon color="inherit">
                    <MySubscriptionLogo />
                </SvgIcon>
            ),
            buttonProps: bottle.container?.FeatureFlags?.removeMySubscriptionsEnabled
                ? {
                      id: "MySubscriptions",
                      onClick: () => {
                          window.open(`${configOptions.portalBaseUrl}/${orgId}/notifications`, "_blank");
                      }
                  }
                : { id: "MySubscriptions", to: `/${orgId}/lists`, component: RouterLink }
        });

        if (isSuperUser || organization?.isOrgOwner || organization?.hasAccess) {
            orgAppNavBtns.push({
                label: "Administration",
                icon: <SettingsIcon />,
                isAdmin: true,
                drawer: {
                    open: showDrawer,
                    classes: { paper: classes.drawerPaper },
                    className: classes.drawer,
                    onClose: () => {
                        setShowDrawer(false);
                        onNavChange(undefined);
                    },
                    id: "administration-drawer",
                    variant: "temporary",
                    children: (
                        <AdministrationDrawerMenu
                            orgId={orgId}
                            onClick={setShowDrawer}
                            isSuperUser={isSuperUser}
                            isOrgOwner={organization?.isOrgOwner}
                            hasAccess={organization?.hasAccess}
                        />
                    )
                }
            });
        }

        orgAppNavBtns = orgAppNavBtns.sort((a, b) => {
            if (a.label === "Dashboard") return -1;
            if (b.label === "Dashboard") return 1;
            if (a.label === "Administration") return 1;
            if (b.label === "Administration") return -1;
            return a.label.localeCompare(b.label);
        });

        setOrgNavButtons(orgAppNavBtns);
    }, [organization, isSuperUser, orgId, classes.drawer, classes.drawerPaper, showDrawer, configOptions]);

    const onNavChange = useCallback(
        (selected: number | undefined) => {
            if (selected) {
                setActiveBtn(selected);
            }

            if (selected === undefined) {
                const index = orgNavButtons.findIndex((btn) => location.pathname === btn.buttonProps?.to);
                setActiveBtn(index !== -1 ? index : orgNavButtons.length - 1);
            }

            if (location.pathname.includes(orgNavButtons[selected!]?.buttonProps?.to)) {
                setActiveBtn(selected);
            }

            if (selected && orgNavButtons[selected].isAdmin) {
                setShowDrawer(true);
            } else if (selected === undefined && location.pathname.includes("admin")) {
                const index = orgNavButtons.findIndex((btn) => location.pathname === btn.buttonProps?.to);
                if (orgNavButtons[index]?.isAdmin || index === -1) {
                    setShowDrawer(!showDrawer);
                }
            } else {
                setShowDrawer(false);
            }
        },
        [location, orgNavButtons, showDrawer]
    );

    useEffect(() => {
        const lastOrg = localStorage.getItem("organization:Notifications");
        const lastOrgFormatted = lastOrg && lastOrg!.replace(/['"]+/g, "");

        async function init() {
            if (orgService) {
                const orgId = lastOrgFormatted ? lastOrgFormatted : orgService.orgId;
                const signoutUrl = await userManager.getSignoutUrl(orgId);

                setOrganization(orgService.organization);
                setOrgId(lastOrgFormatted ? lastOrgFormatted : orgId);
                setIsAuthenticated(orgService.user !== null);
                setSignoutUrl(signoutUrl);
            }

            const isSuperUser = await userManager.isSuperUser();

            setIsSuperUser(isSuperUser);
            setIsLoading(false);
            buildNavigation();
            await setupZenDesk();
        }
        init();
    }, [orgService, setupZenDesk, userManager, buildNavigation]);

    const pageOrganization: CppOrganization | undefined = useMemo(() => {
        if (organization != null) {
            return getCppOrganizationFromOrganization(organization);
        }

        return undefined;
    }, [organization]);

    const orgServiceSdk = useMemo(
        () => new NotificationsOrganizationService(apiService, authUser),
        [authUser, apiService]
    );

    useEffect(() => {
        if (orgNavButtons.length > 1 && activeBtn === undefined) {
            const index = orgNavButtons.findIndex((btn) => location.pathname.includes(btn.buttonProps?.to));
            setActiveBtn(index !== -1 ? index : 0);
        }
    }, [orgNavButtons]);

    const onOrgSelect = (org: CppOrganization) => {
        bottle.container.WindowRouter.assign(`${window.location.origin}/${org.name}/${isCitizenView ? "" : "admin"}`);
    };

    const helpMenuOptions = useMemo(() => {
        const menuItems: MenuItem[] = [
            {
                children: "Help Center",
                onClick: () =>
                    window.open(
                        "https://www.platform.civicplus.help/hc/en-us/categories/360004366394-CivicPlus-Notifications"
                    )
            },
            {
                children: "System Status",
                onClick: () =>
                    window.open(
                        "https://www.status.civicplus.help/hc/en-us/community/topics/360000112533-CivicPlus-Platform"
                    )
            },
            {
                children: "Chat with Support",
                onClick: () => {
                    Zendesk.show();
                }
            }
        ];

        return {
            menuItems,
            show: !isCitizenView
        };
    }, [isCitizenView]);

    return (
        <Page
            id="civic-notify-page-wrapper"
            title="Notifications • CivicPlus"
            product="notifications"
            currentOrg={pageOrganization}
            onOrgSelect={onOrgSelect}
            orgServiceSdk={orgServiceSdk}
            className={classes.page}
            OrgSwitcherProps={{
                showGeolocation: false,
                showRecentOrganizations: true,
                hide: false,
                superUserRole: undefined
            }}
            helpMenuOptions={helpMenuOptions}
            type={isCitizenView ? "external" : "internal"}
            disableSidebar={true}
            accountMenuOptions={{
                includeDefault: false,
                show: true,
                signoutUrl: `${signoutUrl}`,
                menuItems: [
                    {
                        children: "Reset Password",
                        onClick: () => window.open(userManager.getResetPasswordUrl(), "_blank")
                    },
                    {
                        children: "Settings",
                        onClick: () => window.open(userManager.getSettingsUrl(), "_blank")
                    },
                    {
                        children: "Sign Out",
                        onClick: () => userManager.signOut()
                    }
                ]
            }}
            productNavSections={[
                {
                    title: "Notifications"
                }
            ]}
            navigationRailProps={{
                className: classes.navRail,
                active: activeBtn,
                buttons: orgNavButtons,
                xs: 4,
                onChange: onNavChange,
                "aria-label": "CivicPlus Notifications Navigation"
            }}
        >
            {isLoading ? <></> : <Pendo />}
            {isLoading ? <Loader verticallyCenter={true} /> : children}
        </Page>
    );
};

export default PageWrapperInternal;

export const PageWrapper = withRouter(PageWrapperInternal);
